import classNames from 'classnames';

import { useExplorationContext } from '@/explore/exploration/exploration-context';
import { Loader } from '@/components/loader';
import { CloseButton } from '@/components/button';

import {
  DereferencedPipeline,
  Metric,
  Model,
  Visualisation,
  isAggregatedVisualisation,
  isSimpleVisualisation,
} from '../../types';
import { VisualisationGraph } from './visualisation-graph';
import { getDereferencedPipelineFields } from '../../pipeline/state';
import { AggregatedVisualisationOptionsForm } from './visualisation-options-form/aggregated-visualisation-options-form';
import { SimpleVisualisationOptionsForm } from './visualisation-options-form/simple-visualisation-options-form';

import styles from './visualisation.module.scss';

interface VisualisationPanelProps {
  pipeline: DereferencedPipeline;
  models: Model[];
  metrics: Metric[];
  accountId: string;
  visualisation: Visualisation;
  onClose(): void;
  onCategroryClick?: (e: { clientX: number; clientY: number }, key: string, value: any) => void;
  onVisualisationChange: (visualisation: Visualisation) => void;
  isInView: boolean;
  isResized: boolean;
}

export const VisualisationPanel = (props: VisualisationPanelProps) => {
  const {
    pipeline,
    models,
    metrics,
    accountId,
    visualisation,
    isResized,
    onClose,
    onCategroryClick,
    onVisualisationChange,
  } = props;

  const { getVariables } = useExplorationContext();

  const showOptions =
    (isAggregatedVisualisation(visualisation) &&
      visualisation.aggregation.aggregations.length === 1 &&
      visualisation.aggregation.groups.length < 2) ||
    (isSimpleVisualisation(visualisation) && visualisation.mainAxisKey !== undefined);

  const fields = getDereferencedPipelineFields(pipeline, {
    models,
    variables: getVariables(),
    metrics,
  });

  const chartOptions = showOptions ? (
    isAggregatedVisualisation(visualisation) ? (
      <AggregatedVisualisationOptionsForm
        visualisation={visualisation}
        onChange={onVisualisationChange}
        metrics={metrics}
        fields={fields}
        excludeAggregationTypes={['custom', 'earliest', 'latest', 'first', 'last']}
      />
    ) : (
      <SimpleVisualisationOptionsForm
        visualisation={visualisation}
        onChange={onVisualisationChange}
        fields={fields}
      />
    )
  ) : null;

  return (
    <div className={classNames(styles.visualisationCard)}>
      <Header onClose={onClose}>{chartOptions}</Header>
      <VisualisationGraph
        pipeline={pipeline}
        fields={fields}
        visualisation={visualisation}
        onCategroryClick={onCategroryClick}
        onStackingChange={(stacked) =>
          onVisualisationChange({
            ...visualisation,
            viewOptions: { ...visualisation.viewOptions, stacked },
          })
        }
        accountId={accountId}
        variables={getVariables()}
        isInView={props.isInView}
        isResized={isResized}
      />
    </div>
  );
};

interface VisualisationLoaderProps {
  compact?: boolean;
  isResized?: boolean;
}

export const VisualisationLoader = ({
  compact = false,
  isResized = false,
}: VisualisationLoaderProps) => (
  <div
    className={classNames(styles.visualisationCard, styles.visualisationLoader, {
      [styles.compact]: compact,
      [styles.isResized]: isResized,
    })}>
    <div className={styles.message}>
      <Loader />
    </div>
  </div>
);

interface HeaderProps {
  children?: React.ReactNode;
  onClose: () => void;
}

const Header = ({ children, onClose }: HeaderProps) => (
  <div className={styles.header}>
    <div className={styles.toolbar}>{children}</div>
    <div className={styles.close}>
      <CloseButton onClick={onClose} iconSize="regular" />
    </div>
  </div>
);
