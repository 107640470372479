import classNames from 'classnames';
import { Cell } from '@tanstack/react-table';

import { useAccountTimezone } from '@/lib/accounts/context';

import { formatPropertyValue, limitLength } from '../../utils/format';
import { DataTableProperty } from './types';

import tableStyle from '@/components/table/table.module.scss';

const MaxDataLength = 500;

export function getCellTitle(cell: Cell<any, unknown>): string {
  const value = cell.getValue();
  if (typeof value === 'object') {
    return limitLength(JSON.stringify(value), MaxDataLength);
  }
  return limitLength(value?.toString() ?? '', MaxDataLength);
}

interface CellValueProps {
  value: unknown;
  property: DataTableProperty;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const CellValue = (props: CellValueProps) => {
  const { value, property, onClick } = props;

  const timezone = useAccountTimezone();

  return (
    <div
      className={classNames({
        [tableStyle.clickable]: onClick !== undefined,
        [tableStyle.hasLink]: property.buildLink !== undefined && value !== null,
      })}
      onClick={onClick}>
      {formatPropertyValue(value, property.type, {
        timezone,
        precision: property.precision,
        maxLength: MaxDataLength,
        format: property.format,
      })}
    </div>
  );
};
