import { first, isNumber } from 'lodash';
import { common } from '@gosupersimple/types';

import { pluralize } from '@/lib/utils/string';

import {
  AddRelatedColumnOperation,
  CompositeFilterCondition,
  DeriveFieldOperation,
  Fields,
  FilterCondition,
  FilterOperation,
  GroupAggregateOperation,
  Model,
  PipelineOperation,
  RelationAggregateOperation,
  SqlOperation,
  SwitchToRelationOperation,
} from '../types';
import { isSingleFilterCondition, isValueExpression } from '../pipeline/operation';
import { findFieldName, precisionToTimeInterval } from '../pipeline/state';
import { formatMetricLabel } from '../utils/metrics';
import { getModelRelation } from '../model/utils';
import { getTimeAggregationPeriodLabel } from './format';
import { humanizeExpression } from '../utils/penguino';
import { Token } from '../components/pipeline/operation-description';

export const getOperationIcon = (operation: PipelineOperation) => {
  switch (operation.operation) {
    case 'invalid':
      return 'AlertTriangle';
    case 'filter':
      return 'Filter';
    case 'switchToRelation':
      return 'RelatedData';
    case 'relationAggregate':
    case 'addRelatedColumn':
    case 'deriveField':
      return 'AddColumn';
    case 'groupAggregate':
      return 'Summarise';
    case 'sql':
      return 'Database';
    default:
      return 'Filter';
  }
};

export const getOperationOverview = (
  operation: PipelineOperation,
  { fields, model, variables }: { fields: Fields; model: Model; variables: common.QueryVariables },
): Token => {
  switch (operation.operation) {
    case 'invalid':
      return operation.parameters.message ?? 'Invalid operation';
    case 'filter':
      return getFilterOperationOverview(operation, fields);
    case 'deriveField':
      return getDeriveFieldOperationOverview(operation, fields);
    case 'groupAggregate':
      return getGroupAggregateOperationOverview(operation, fields, variables);
    case 'relationAggregate':
      return getRelationAggregateOperationOverview(operation, fields);
    case 'addRelatedColumn':
      return getAddRelatedColumnOperationOverview(operation);
    case 'sql':
      return getSqlOperationOverview(operation);
    case 'switchToRelation':
      return getSwitchToRelationOperationOverview(operation, model);
    default:
      return '';
  }
};

const getFilterOperationOverview = (operation: FilterOperation, fields: Fields) =>
  getCompositeFilterConditionLabel(operation.parameters, fields);

const getCompositeFilterConditionLabel = (
  condition: CompositeFilterCondition,
  fields: Fields,
): Token => {
  if (isSingleFilterCondition(condition)) {
    return getFilterConditionLabel(condition, fields);
  }

  return condition.operands
    .map((operand) => getCompositeFilterConditionLabel(operand, fields))
    .flatMap((value, index, array) =>
      array.length - 1 !== index ? [value, condition.operator.toUpperCase()] : [value],
    );
};

const getFilterConditionLabel = (condition: FilterCondition, fields: Fields): Token => {
  const { key, operator } = condition;
  const value = isNumber(condition.value)
    ? { value: condition.value, kind: 'value' }
    : isValueExpression(condition.value)
      ? { value: humanizeExpression(condition.value.expression, fields), kind: 'expression' }
      : { value: condition.value?.toLocaleString() ?? '', kind: 'value' };

  if (filterIsUnaryOperation(condition)) {
    return [findFieldName(fields, key), getOperatorLabel(operator)];
  }

  return [findFieldName(fields, key), getOperatorLabel(operator), value];
};

const filterIsUnaryOperation = ({ operator }: FilterCondition) =>
  operator === 'isnull' || operator === 'isnotnull';

const OPERATOR_LABELS: { [key: string]: string } = {
  noticontains: 'does not contain text',
  icontains: 'contains text',
  arrcontains: 'array contains',
  isnull: 'has no value',
  isnotnull: 'has a value',
  '==': 'is',
  '!=': 'is not',
  '>': '>',
  '<': '<',
  '>=': '≥',
  '<=': '≤',
};

const getOperatorLabel = (operator: string) => OPERATOR_LABELS[operator] || operator;

const getSliceDescription = (slice: common.Slice, fields: Fields) => {
  const { limit = 0, offset = 0, sort = [] } = slice;
  const sortFieldName = findFieldName(fields, first(sort)?.key ?? '');
  const isAsc = first(sort)?.direction === 'ASC';
  return `first ${limit} ${pluralize(limit, 'row', 'rows')} ${offset > 0 ? `skipping ${offset}` : ''}${` sorted by ${sortFieldName} ${isAsc ? 'Asc' : 'Desc'}`}`;
};

const getDeriveFieldOperationOverview = (operation: DeriveFieldOperation, fields: Fields) => {
  return {
    value: humanizeExpression(operation.parameters.value.expression, fields),
    kind: 'expression',
    label: operation.parameters.fieldName,
  };
};

const getGroupAggregateOperationOverview = (
  operation: GroupAggregateOperation,
  fields: Fields,
  variables: common.QueryVariables,
) => {
  const parts = [];
  const slice = operation.parameters.slice;

  const hasAggregations = operation.parameters.aggregations.length > 0;
  const hasGrouping = operation.parameters.groups.length > 0;
  const hasSlicing = slice !== undefined;

  if (hasAggregations) {
    parts.push(
      operation.parameters.aggregations
        .map((aggregation) =>
          aggregation.type === 'metric'
            ? formatMetricLabel(aggregation.property.name)
            : aggregation.property.name,
        )
        .join(', '),
    );
  }

  if (hasGrouping) {
    parts.push(hasAggregations ? 'per' : 'Group by');
    parts.push(
      operation.parameters.groups
        .map(({ key, precision }) => {
          const interval = precisionToTimeInterval(precision, variables);
          return `${findFieldName(fields, key)}${
            interval !== undefined ? ` by ${getTimeAggregationPeriodLabel(interval)}` : ''
          }`;
        })
        .join(', '),
    );
  }

  if (hasSlicing) {
    const description = getSliceDescription(slice, fields);
    parts.push(hasAggregations || hasGrouping ? `(${description})` : `Take ${description}`);
  }

  return parts;
};

const getRelationAggregateOperationOverview = (
  operation: RelationAggregateOperation,
  fields: Fields,
) => {
  const { aggregations, slice } = operation.parameters;
  const hasSlicing = slice !== undefined;

  const aggregationsDescription = aggregations
    .map((aggregation) => aggregation.property.name)
    .join(', ');

  return hasSlicing
    ? `${aggregationsDescription} (${getSliceDescription(slice, fields)})`
    : aggregationsDescription;
};

const getAddRelatedColumnOperationOverview = (operation: AddRelatedColumnOperation) =>
  operation.parameters.columns.map((column) => column.property.name).join(', ');

const getSqlOperationOverview = (operation: SqlOperation) => ({
  kind: 'expression',
  value: operation.parameters.sql.replaceAll('\n', '').slice(0, 50),
});

const getSwitchToRelationOperationOverview = (operation: SwitchToRelationOperation, model: Model) =>
  formatShortRelationLabel({
    relationName:
      getModelRelation(operation.parameters.relation.key, model)?.name ??
      operation.parameters.relation.key,
    baseModelName: model.name,
  });

const formatShortRelationLabel = ({
  relationName,
  baseModelName,
}: {
  relationName: string;
  baseModelName: string;
}) => `${relationName} on ${baseModelName}`;
