import { ForwardRefExoticComponent, useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';

import { InlineButton } from '@/components/button';

import { countRecords } from '../../grouping';
import { DataTableProperty, DataTableRow } from '../datatable/types';
import { DereferencedPipeline, Model, QueryVariables, Sort } from '../../types';
import { useFitContainerSlice } from './hooks';

import { PageIndexes } from './types';

import style from './paginated-records.module.scss';

const rowHeight = parseInt(style.tableRowHeight);
const fallBackPageSize = 10;
export interface PaginatedRecordsComponentProps {
  records: DataTableRow[];
  properties: DataTableProperty[];
  model: Model;
  sort: Sort;
  setSort: (sort: Sort[number] | null) => void;
  canEditPipeline?: boolean;
  loading?: boolean;
}

interface PaginatedRecordsProps extends PaginatedRecordsComponentProps {
  fetchMore: () => void;
  footerContent?: React.ReactNode;
  component: ForwardRefExoticComponent<
    PaginatedRecordsComponentProps & React.RefAttributes<HTMLDivElement>
  >;
  defaultPageSize?: number;
  pipeline?: DereferencedPipeline;
  variables?: QueryVariables;
  cellHeight?: number;
}

export const PaginatedRecords = (props: PaginatedRecordsProps) => {
  const {
    records,
    pipeline,
    variables,
    cellHeight,
    defaultPageSize,
    fetchMore,
    loading = false,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [pageIndexes, setPageIndexes] = useState<PageIndexes>({
    startIndex: 0,
    endIndex: null,
  });
  const [paginationResetKey, setPaginationResetKey] = useState({
    pipeline,
    variables,
  });
  const [endIndexResetkey, setEndIndexResetKey] = useState({
    cellHeight,
    defaultPageSize,
  });
  const [pageSize, setPageSize] = useState<number>(defaultPageSize ?? fallBackPageSize);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);

  const containerHeight = containerRef.current?.clientHeight;
  const recordCount = countRecords(records);
  const hasRecords = recordCount > 0;
  const hasNext = pageIndexes.endIndex !== null && recordCount > pageIndexes.endIndex;
  const hasPrevious = pageIndexes.startIndex !== null && pageIndexes.startIndex > 0;
  const isCalculatingContainerFit =
    pageIndexes.startIndex === null || pageIndexes.endIndex === null;
  const isPaginationNeeded =
    (hasRecords && (hasNext || hasPrevious)) || isCalculatingContainerFit || loading;

  useEffect(() => {
    if (containerHeight !== undefined && containerHeight > rowHeight) {
      const calculatedPageSize =
        (Math.floor((containerHeight - rowHeight) / rowHeight) || defaultPageSize) ??
        fallBackPageSize;

      setPageSize(calculatedPageSize);
    }
  }, [cellHeight, containerHeight, defaultPageSize]);

  useEffect(() => {
    if (!isEqual(paginationResetKey, { pipeline, variables })) {
      setPageIndexes({ startIndex: 0, endIndex: null });
      setPaginationResetKey({ pipeline, variables });
    }
  }, [paginationResetKey, pipeline, variables]);

  useEffect(() => {
    if (!isEqual(endIndexResetkey, { cellHeight, defaultPageSize: pageSize })) {
      if (pageIndexes.startIndex === null) {
        return;
      }
      setPageIndexes({ startIndex: pageIndexes.startIndex, endIndex: null });
      setEndIndexResetKey({ cellHeight, defaultPageSize: pageSize });
    }
  }, [cellHeight, pageSize, endIndexResetkey, pageIndexes.startIndex]);

  useEffect(() => {
    if (pageIndexes.endIndex !== null && pageSize > 0) {
      const calculatedPageNumber = Math.ceil(pageIndexes.endIndex / pageSize);
      setCurrentPageNumber(calculatedPageNumber);
    }
  }, [pageIndexes.endIndex, pageSize]);

  const handleNextPage = () => {
    if (pageIndexes.endIndex === null) {
      return;
    }
    const startIndex = (pageIndexes.startIndex ?? 0) + pageSize;
    setPageIndexes({
      startIndex,
      endIndex: startIndex + pageSize,
    });
    if (pageIndexes.endIndex + 2 * pageSize + 1 >= recordCount) {
      fetchMore();
    }
  };

  const handlePreviousPage = () => {
    if (pageIndexes.startIndex === null) {
      return;
    }
    const startIndex = Math.max(0, pageIndexes.startIndex - pageSize);
    setPageIndexes({
      startIndex: startIndex,
      endIndex: startIndex + pageSize,
    });
  };

  const slice = useFitContainerSlice(
    containerRef,
    records,
    pageIndexes,
    cellHeight !== undefined,
    setPageIndexes,
    pageSize,
    loading,
  );

  return (
    <>
      <props.component
        records={slice}
        properties={props.properties}
        model={props.model}
        sort={props.sort}
        setSort={props.setSort}
        canEditPipeline={props.canEditPipeline}
        loading={loading}
        ref={containerRef}
      />

      {isPaginationNeeded && (
        <div className={style.footer}>
          <div className={style.leftContainer}>{props.footerContent}</div>

          <div className={style.centerContainer}>
            <InlineButton
              onClick={handlePreviousPage}
              disabled={!hasPrevious || loading}
              size="small">
              Previous
            </InlineButton>
            <div className={style.pageNumber}>{currentPageNumber}</div>
            <InlineButton onClick={handleNextPage} disabled={!hasNext || loading} size="small">
              Next
            </InlineButton>
          </div>

          <div className={style.rightContainer} />
        </div>
      )}
    </>
  );
};
