import { cloneDeep, first, nth, omit } from 'lodash';

import {
  AddRelatedColumnOperation,
  BasePipeline,
  Cell,
  Exploration,
  JoinPipelineOperation,
  Metric,
  Model,
  Pipeline,
  QueryVariables,
  RecordsCell,
  RelationAggregateOperation,
} from '@/explore/types';
import {
  dereferencePipeline,
  flattenPipeline,
  generatePipelineId,
  getOrGeneratePipelineId,
} from '@/explore/pipeline/utils';
import {
  InvalidPipelineReferenceError,
  generateCellId,
  generateExplorationId,
} from '@/explore/utils';

import { getFinalState } from '@/explore/pipeline/state';

import { ensureValidLayout } from '../exploration-layout/utils';

export const isBasePipeline = (pipeline: Pipeline): pipeline is BasePipeline =>
  'baseModelId' in pipeline;

export const addExplorationCells = (exploration: Exploration, cells: Cell[], index = 0) => ({
  ...exploration,
  view: {
    ...exploration.view,
    cells: [
      ...exploration.view.cells.slice(0, index),
      ...cells,
      ...exploration.view.cells.slice(index),
    ],
  },
});

export const prepareExplorationCellsForImport = (cells: Cell[]) => {
  const pipelineIdsToReplace = getPipelineIdsInCells(cells);
  const cellsWithNewPipelineIds = pipelineIdsToReplace.reduce<Cell[]>((acc, pipelineId) => {
    const newPipelineId = generatePipelineId();
    return replacePipelineIdInCells(acc, pipelineId, newPipelineId);
  }, cells);
  return cellsWithNewPipelineIds.map((cell) => ({
    ...cell,
    id: generateCellId(),
    ...('pipeline' in cell ? { pipeline: cell.pipeline } : {}),
    viewOptions: omit(cell.viewOptions, 'rowId'),
  }));
};

export const importExplorationCellsToExploration = (
  exploration: Exploration,
  cells: Cell[],
  index = 0,
) => addExplorationCells(exploration, prepareExplorationCellsForImport(cells), index);

export const importExplorationCellsAfter = (
  exploration: Exploration,
  cells: Cell[],
  cellId: string,
) => importExplorationCellsToExploration(exploration, cells, getCellIndex(cellId, exploration) + 1);

export const duplicateExplorationCellAtIdx = (exploration: Exploration, index: number) => {
  const cells = exploration.view.cells;
  const cell = getCellByIndex(index, exploration);

  if (cell === undefined) {
    return exploration;
  }

  if ('pipeline' in cell) {
    const cellCopy = duplicateCell(cell);

    return {
      ...exploration,
      view: {
        ...exploration.view,
        cells: ensureValidLayout([
          ...cells.slice(0, index + 1),
          cellCopy,
          ...cells.slice(index + 1),
        ]),
      },
    };
  }

  const cellCopy = { ...cloneDeep(cell), id: generateCellId() };

  return {
    ...exploration,
    view: {
      ...exploration.view,
      cells: [...cells.slice(0, index + 1), cellCopy, ...cells.slice(index + 1)],
    },
  };
};

export const createExplorationCellInstanceAtIdx = (
  exploration: Exploration,
  index: number,
): Exploration => {
  const cell = getCellByIndex(index, exploration);

  if (cell === undefined || !('pipeline' in cell)) {
    return exploration;
  }

  const pipelineId = getOrGeneratePipelineId(cell.pipeline);

  const copy = {
    ...omit(cloneDeep(cell), 'visualisations'),
    id: generateCellId(),
    title: `Instance of ${cell.title}`,
    pipeline: { parentId: pipelineId, operations: [], pipelineId: generatePipelineId() },
  };

  const cells = cloneDeep(exploration.view.cells);

  return {
    ...exploration,
    view: {
      ...exploration.view,
      cells: ensureValidLayout([
        ...cells.slice(0, index),
        { ...cell, pipeline: { ...cell.pipeline, pipelineId } },
        copy,
        ...cells.slice(index + 1),
      ]),
    },
  };
};

export const deleteExplorationCellById = (
  exploration: Exploration,
  cellId: string,
): Exploration => {
  const deletedCell = getCell(cellId, exploration);

  if (deletedCell === undefined) {
    throw new Error(`Could not find cell with id ${cellId}`);
  }

  const cells = exploration.view.cells;
  const updatedCells = cells
    .filter((cell) => cell.id !== cellId)
    .map((cell) => {
      if (
        'pipeline' in cell &&
        'pipeline' in deletedCell &&
        'pipelineId' in deletedCell.pipeline &&
        'parentId' in cell.pipeline &&
        cell.pipeline.parentId === deletedCell.pipeline.pipelineId
      ) {
        const { baseModelId, operations } = flattenPipeline(cell.pipeline, exploration);
        return {
          ...cell,
          pipeline: {
            baseModelId,
            operations,
            ...('pipelineId' in cell.pipeline ? { pipelineId: cell.pipeline.pipelineId } : {}),
          },
        };
      }

      return cell;
    });

  return { ...exploration, view: { ...exploration.view, cells: updatedCells } };
};

export const getCell = (cellId: string, exploration: Exploration) =>
  exploration.view.cells.find((cell) => cell.id === cellId);

export const getCellByIndex = (index: number, exploration: Exploration) =>
  nth(exploration.view.cells, index);

export const getCellIndex = (cellId: string, exploration: Exploration) =>
  exploration.view.cells.findIndex((cell) => cell.id === cellId);

export const getCellByPipelineId = (pipelineId: string, exploration: Exploration) => {
  return exploration.view.cells.find(
    (cell) =>
      'pipeline' in cell &&
      'pipelineId' in cell.pipeline &&
      cell.pipeline.pipelineId === pipelineId,
  );
};

export const getCellByPipelineIdOrThrow = (pipelineId: string, exploration: Exploration) => {
  const cell = getCellByPipelineId(pipelineId, exploration);
  if (cell === undefined) {
    throw new InvalidPipelineReferenceError(`Could not find cell with pipeline id ${pipelineId}`);
  }
  return cell;
};

export const getCellIdByPipelineId = (pipelineId: string, exploration: Exploration) =>
  exploration.view.cells.find(
    (cell) =>
      'pipeline' in cell &&
      'pipelineId' in cell.pipeline &&
      cell.pipeline.pipelineId === pipelineId,
  )?.id;

export const getPipelineById = (pipelineId: string, exploration: Exploration) => {
  const cell = exploration.view.cells.find(
    (cell) =>
      'pipeline' in cell &&
      'pipelineId' in cell.pipeline &&
      cell.pipeline.pipelineId === pipelineId,
  );

  if (cell === undefined || !('pipeline' in cell) || !('pipelineId' in cell.pipeline)) {
    throw new InvalidPipelineReferenceError(`Could not find pipeline with id ${pipelineId}`);
  }

  return cell.pipeline;
};

export const getRecordsCell = (cellId: string, exploration: Exploration) => {
  const cell = getCell(cellId, exploration);
  if (cell === undefined || !isRecordsCell(cell)) {
    throw new Error(`Cell ${cellId} not found or is not RecordsCell`);
  }
  return cell;
};

export const isRecordsCell = (cell: Cell): cell is RecordsCell => cell.kind === 'records';

export const getFieldsByCellId = (
  cellId: string,
  exploration: Exploration,
  models: Model[],
  variables: QueryVariables,
  metrics: Metric[],
) => {
  const cell = getCell(cellId, exploration);
  if (cell === undefined || !('pipeline' in cell)) {
    return [];
  }
  const { baseModelId, operations } = dereferencePipeline(cell.pipeline, exploration);
  return getFinalState(baseModelId, operations, { models, variables, metrics }).fields;
};

export type ReplicableCell = RecordsCell;

export const isReplicableCell = (cell: Cell): cell is ReplicableCell => cell.kind === 'records';

const getReplicableExplorationCells = (exploration: Exploration): ReplicableCell[] =>
  exploration.view.cells.filter(isReplicableCell);

export const explorationHasReplicableCells = (exploration: Exploration) =>
  getReplicableExplorationCells(exploration).length > 0;

export const duplicateCell = (cell: Cell): Cell => ({
  ...cloneDeep(cell),
  id: generateCellId(),
  ...('title' in cell ? { title: `Copy of ${cell.title}` } : {}),
  ...('pipeline' in cell
    ? {
        pipeline: {
          ...cloneDeep(cell.pipeline),
          pipelineId: generatePipelineId(),
        },
      }
    : {}),
});

export const cloneCell = (cell: Cell): Cell => ({
  ...cloneDeep(cell),
  id: generateCellId(),
  ...('pipeline' in cell && 'pipelineId' in cell.pipeline && cell.pipeline.pipelineId !== undefined
    ? {
        pipeline: {
          pipelineId: generatePipelineId(),
          parentId: cell.pipeline.pipelineId,
          operations: [],
        },
      }
    : {}),
});

const replacePipelineIdInAddRelatedColumnOperation = (
  operation: AddRelatedColumnOperation,
  oldId: string,
  newId: string,
) =>
  'pipelineId' in operation.parameters && operation.parameters.pipelineId === oldId
    ? {
        ...operation,
        parameters: {
          ...operation.parameters,
          pipelineId: newId,
        },
      }
    : operation;

const replacePipelineIdInJoinPipelineOperation = (
  operation: JoinPipelineOperation,
  oldId: string,
  newId: string,
) =>
  'parentId' in operation.parameters.pipeline && operation.parameters.pipeline.parentId === oldId
    ? {
        ...operation,
        parameters: {
          ...operation.parameters,
          pipeline: {
            ...operation.parameters.pipeline,
            parentId: newId,
          },
        },
      }
    : operation;

const replacePipelineIdInRelationAggregateOperation = (
  operation: RelationAggregateOperation,
  oldId: string,
  newId: string,
) =>
  'pipelineId' in operation.parameters && operation.parameters.pipelineId === oldId
    ? {
        ...operation,
        parameters: {
          ...operation.parameters,
          pipelineId: newId,
        },
      }
    : operation;

const replacePipelineIdInCells = (cells: Cell[], oldId: string, newId: string): Cell[] =>
  cells.map((cell) => replacePipelineIdInCell(cell, oldId, newId));

const replacePipelineIdInCell = (cell: Cell, oldId: string, newId: string): Cell => {
  if ('pipeline' in cell && 'pipelineId' in cell.pipeline) {
    return {
      ...cell,
      pipeline: {
        ...cell.pipeline,
        pipelineId: cell.pipeline.pipelineId === oldId ? newId : cell.pipeline.pipelineId,
        operations: cell.pipeline.operations.map((operation) => {
          switch (operation.operation) {
            case 'addRelatedColumn':
              return replacePipelineIdInAddRelatedColumnOperation(operation, oldId, newId);
            case 'joinPipeline':
              return replacePipelineIdInJoinPipelineOperation(operation, oldId, newId);
            case 'relationAggregate':
              return replacePipelineIdInRelationAggregateOperation(operation, oldId, newId);
            default:
              return operation;
          }
        }),
      },
    };
  }
  return cell;
};

const getPipelineReferencesByOperations = (operations: BasePipeline['operations']) => {
  const pipelineIds = new Set<string>();

  operations.forEach((operation) => {
    switch (operation.operation) {
      case 'addRelatedColumn':
        if ('pipelineId' in operation.parameters && operation.parameters.pipelineId !== undefined) {
          pipelineIds.add(operation.parameters.pipelineId);
        }
        break;
      case 'joinPipeline':
        if (
          'parentId' in operation.parameters.pipeline &&
          operation.parameters.pipeline.parentId !== undefined
        ) {
          pipelineIds.add(operation.parameters.pipeline.parentId);
        }
        break;
      case 'relationAggregate':
        if ('pipelineId' in operation.parameters && operation.parameters.pipelineId !== undefined) {
          pipelineIds.add(operation.parameters.pipelineId);
        }
        break;
    }
  });

  return Array.from(pipelineIds);
};

const getPipelineIdsInCells = (cells: Cell[]) =>
  cells.reduce<string[]>(
    (acc, cell) =>
      'pipeline' in cell && 'pipelineId' in cell.pipeline && cell.pipeline.pipelineId !== undefined
        ? [...acc, cell.pipeline.pipelineId]
        : acc,
    [],
  );

const extractCellWithReferencesFromExploration = (
  cell: Cell,
  exploration: Exploration,
): { cell: Cell; dependencies: Cell[] } => {
  if ('pipeline' in cell) {
    const extractedPipeline = flattenPipeline(cell.pipeline, exploration);
    const referencedPipelineIds = getPipelineReferencesByOperations(extractedPipeline.operations);
    const referencedCells = referencedPipelineIds.flatMap((pipelineId) => {
      const { cell: referencedCell, dependencies: referencedCellDependencies } =
        extractCellWithReferencesFromExploration(
          getCellByPipelineIdOrThrow(pipelineId, exploration),
          exploration,
        );
      return [referencedCell, ...referencedCellDependencies];
    });
    return { cell: { ...cell, pipeline: extractedPipeline }, dependencies: referencedCells };
  }

  return { cell, dependencies: [] };
};

export const extractCellFromExploration = (
  cellId: string,
  exploration: Exploration,
): { cell: Cell; dependencies: Cell[] } => {
  const cell = getCell(cellId, exploration);

  if (cell === undefined) {
    throw new Error(`Could not find cell with id ${cellId}`);
  }

  if ('pipeline' in cell) {
    return cloneDeep(extractCellWithReferencesFromExploration(cell, exploration));
  }

  return { cell: cloneDeep(cell), dependencies: [] };
};

export const buildExplorationFromCells = (cells: Cell[]): Exploration => {
  const firstCell = first(cells);
  const name =
    (firstCell !== undefined && 'title' in firstCell ? firstCell.title : undefined) ?? 'Untitled';
  return {
    explorationId: generateExplorationId(),
    name,
    labels: {},
    parameters: [],
    view: { canvas: undefined, cells },
  };
};
