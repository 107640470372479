import { useMemo } from 'react';
import { last } from 'lodash';

import { Select } from '@/components/form/select';
import { SearchInput } from '@/components/form/search-input';

import {
  AggregatedVisualisation,
  Aggregation,
  AggregationExtendedType,
  Fields,
  Grouping,
  Metric,
} from '../../../types';
import {
  aggregationExtendedTypeToAggregationType,
  aggregationTypeToAggregationExtendedType,
  buildMetricAggregation,
  changeAggregationType,
  generateAggregationProperty,
  getAggregationOptionsForFields,
  getFieldOptionsForAggregationType,
} from '../../../utils/aggregation';
import { GroupingSelect } from '../../grouping-select';
import { metricAggregationOptions, metricById } from '../../../utils/metrics';
import { isKeyedAggregation } from '../../../pipeline/operation';
import { setVisualisationAggregations } from '../utils';

interface AggregatedVisualisationOptionsFormProps {
  visualisation: AggregatedVisualisation;
  onChange: (visualisation: AggregatedVisualisation) => void;
  fields: Fields;
  metrics?: Metric[];
  excludeAggregationTypes?: AggregationExtendedType[];
}

export const AggregatedVisualisationOptionsForm = (
  props: AggregatedVisualisationOptionsFormProps,
) => {
  const { visualisation, onChange, fields, metrics = [], excludeAggregationTypes = [] } = props;

  const aggregation = visualisation.aggregation.aggregations[0];
  const group = last(visualisation.aggregation.groups);
  const hasGroupings = visualisation.aggregation.groups.length > 0;

  const aggregationOptions = useMemo(
    () =>
      getAggregationOptionsForFields(fields)
        .filter(({ value }) => !excludeAggregationTypes.includes(value))
        .map(({ label, value }) => ({ label, value: value.toString() }))
        .concat(metricAggregationOptions(metrics)),
    [fields, metrics, excludeAggregationTypes],
  );

  const handleAggregationTypeChange = (v: string) => {
    const metric = metricById(metrics, v);
    if (metric !== undefined) {
      handleAggregationChange(buildMetricAggregation(metric));
      return;
    }

    const updatedAggregation = changeAggregationType(
      aggregation,
      aggregationExtendedTypeToAggregationType(v as AggregationExtendedType),
      fields,
    );
    handleAggregationChange(updatedAggregation);
  };

  const handleAggregationChange = (changedAggregation: Aggregation) => {
    const aggregations = [changedAggregation, ...visualisation.aggregation.aggregations.slice(1)];
    onChange(setVisualisationAggregations(visualisation, aggregations, fields));
  };

  const handleGroupingChange = (changedGrouping: Grouping) => {
    const updateVisualisation = {
      ...visualisation,
      aggregation: {
        ...visualisation.aggregation,
        groups: [...visualisation.aggregation.groups.slice(0, -1), changedGrouping],
      },
    };
    return onChange(updateVisualisation);
  };

  const isKeyed = isKeyedAggregation(aggregation);
  const field = isKeyed ? fields.find((f) => f.key === aggregation.key) : undefined;
  if (field === undefined && isKeyed) {
    throw new Error(`Keyed aggregation references unknown field with key '${aggregation.key}'`);
  }

  const aggregationExtendedType = aggregationTypeToAggregationExtendedType(aggregation.type, field);
  const value = aggregation.type === 'metric' ? aggregation.metricId : aggregationExtendedType;

  const typeInput = (
    <Select
      options={aggregationOptions}
      value={value}
      onChange={handleAggregationTypeChange}
      fullWidth={false}
    />
  );

  const targetInput =
    'key' in aggregation && aggregationOptions.length > 0 ? (
      <SearchInput
        options={getFieldOptionsForAggregationType(aggregationExtendedType, fields)}
        value={aggregation.key}
        onChange={(value) => {
          const newAggregation = { ...aggregation, key: value };
          handleAggregationChange({
            ...newAggregation,
            property: generateAggregationProperty(newAggregation, fields, metrics),
          });
        }}
      />
    ) : null;

  return (
    <>
      <div>{typeInput}</div>
      {targetInput !== null ? <div>{targetInput}</div> : null}
      {hasGroupings ? (
        <>
          by
          <GroupingSelect
            grouping={group}
            groupings={visualisation.aggregation.groups}
            fields={fields}
            onChange={handleGroupingChange}
          />
        </>
      ) : null}
    </>
  );
};
