import { z } from 'zod';
import { compact, last, omit, pick, uniq } from 'lodash';

import type {
  AddRelatedColumnOperation,
  DereferencedPipeline,
  Exploration,
  Field,
  Fields,
  FunnelOperation,
  FunnelStep,
  Grouping,
  Model,
  Pipeline,
  PipelineOperation,
  PipelineState,
  PipelineStateRelation,
} from '../types';
import { getCellByPipelineIdOrThrow } from '../exploration/utils';
import { dereferencePipeline } from '../pipeline/utils';
import { getFinalState, PipelineStateContext } from '../pipeline/state';
import { getJoinKeyToSemanticModel, getModel, getModelOrThrow } from '../model/utils';
import { getValidRelationTypesForOperation } from '../edit-pipeline/utils/relation';

export const getStepName = (
  step: FunnelStep,
  models: Model[],
  exploration: Exploration,
): string => {
  const { baseModelId } = getDereferencedStepPipeline(step, exploration);
  const model = getModel(models, baseModelId);
  const modelName = model?.name ?? `❗ Missing model: ${baseModelId}`;
  if ('parentId' in step.pipeline) {
    const cell = getCellByPipelineIdOrThrow(step.pipeline.parentId, exploration);
    return 'title' in cell ? (cell.title ?? model?.name ?? modelName) : (model?.name ?? modelName);
  }
  return model?.name ?? modelName;
};

export const getDereferencedStepPipeline = (
  step: FunnelStep,
  exploration: Exploration,
): DereferencedPipeline => dereferencePipeline(step.pipeline, exploration);

export const getStepPipelineState = (
  step: FunnelStep,
  exploration: Exploration,
  ctx: PipelineStateContext,
) => {
  const { baseModelId, operations } = getDereferencedStepPipeline(step, exploration);
  const stepOperations = 'pipeline' in step ? step.pipeline.operations : [];
  const parentOperations = operations.slice(0, operations.length - stepOperations.length);
  const pipelineState = getFinalState(baseModelId, parentOperations, ctx);
  return pipelineState;
};

const groupKeyValueSchema = z.union([
  // Fields on related models
  z.object({
    modelId: z.string(),
    fieldKey: z.string(),
  }),
  // Fields on event model
  z.object({
    fieldKey: z.string(),
  }),
]);
type GroupKeyValue = z.infer<typeof groupKeyValueSchema>;

export const serializeGroupKeyValue = (reference: GroupKeyValue) =>
  JSON.stringify(pick(reference, 'modelId', 'fieldKey'));

export const parseGroupKeyValue = (value: string) => {
  return groupKeyValueSchema.parse(JSON.parse(value));
};

/**
 * Return the final pipeline states of the funnel steps
 */
export const getFunnelPipelineStates = (
  steps: FunnelStep[],
  exploration: Exploration,
  ctx: PipelineStateContext,
) =>
  steps
    .filter((step) => !isEmptyStep(step))
    .map((step) => getStepPipelineState(step, exploration, ctx));

/**
 * Return the intersection of pipeline fields and the fields of the intersection of related models of all steps.
 */
export const getGroupableFields = (
  steps: FunnelStep[],
  exploration: Exploration,
  ctx: PipelineStateContext,
) => {
  const pipelineStates = getFunnelPipelineStates(steps, exploration, ctx);
  const countingFields = uniq(steps.flatMap((step) => step.fields));
  const sharedFields = getSharedFields(pipelineStates);
  const sharedRelations = getSharedRelations(pipelineStates);

  const canGroupByField = (field: Field) => !countingFields.some((key) => key === field.key);

  const sharedRelatedFields = sharedRelations.map((relation) => {
    const model = getModelOrThrow(ctx.models, relation.modelId);
    return {
      name: `on ${relation.name}`,
      fields: model.properties.filter(canGroupByField).map((property) => ({
        ...property,
        name: property.name,
        key: serializeGroupKeyValue({ modelId: relation.modelId, fieldKey: property.key }),
      })),
    };
  });

  return [
    ...sharedFields
      .filter(canGroupByField)
      .map((field) => ({ ...field, key: serializeGroupKeyValue({ fieldKey: field.key }) })),
    ...sharedRelatedFields.filter((group) => group.fields.length > 0),
  ];
};

export const getSharedFields = (pipelineStates: PipelineState[]) => {
  if (pipelineStates.length === 0) {
    return [];
  }

  const fields = pipelineStates.map((state) => state.fields);
  if (fields.length === 1) {
    return fields[0];
  }

  const [firstFields, ...restFields] = fields;

  return firstFields.reduce<Fields>((acc, field) => {
    if (restFields.every((f) => f.find(({ key }) => key === field.key))) {
      return [...acc, field];
    }

    return acc;
  }, []);
};

export const getSharedRelations = (pipelineStates: PipelineState[]) => {
  if (pipelineStates.length === 0) {
    return [];
  }

  const relations = pipelineStates.map((state) => state.relations);
  const [firstRelations, ...restRelations] = relations;
  const sharedRelations = firstRelations
    .filter((relation) =>
      getValidRelationTypesForOperation('addRelatedColumn').includes(relation.type),
    )
    .reduce<PipelineStateRelation[]>((acc, relation) => {
      if (restRelations.every((r) => r.find(({ modelId }) => modelId === relation.modelId))) {
        return [...acc, relation];
      }

      return acc;
    }, []);

  return sharedRelations;
};

const filterAddedRelatedColumns = (operation: AddRelatedColumnOperation, keys: string[]) => ({
  ...operation,
  parameters: {
    ...operation.parameters,
    columns: operation.parameters.columns.filter((column) => keys.includes(column.property.key)),
  },
});

const removeUnusedRelatedColumns = (funnelOperation: FunnelOperation): FunnelOperation => {
  const funnelGroupKeys = funnelOperation.parameters.groups?.map((group) => group.key) ?? [];
  return {
    ...funnelOperation,
    parameters: {
      ...funnelOperation.parameters,
      steps: funnelOperation.parameters.steps.map((step) => ({
        ...step,
        pipeline: {
          ...step.pipeline,
          operations: step.pipeline.operations
            .map((operation) =>
              operation.operation !== 'addRelatedColumn'
                ? operation
                : filterAddedRelatedColumns(operation, funnelGroupKeys),
            )
            .filter(
              (operation) =>
                operation.operation !== 'addRelatedColumn' ||
                operation.parameters.columns.length > 0,
            ),
        },
      })),
    },
  };
};

const addRelatedColumnOperationExists = (
  pipeline: Pipeline,
  operation: AddRelatedColumnOperation,
) =>
  operation.parameters.columns.every((column) =>
    pipeline.operations.some(
      (operation) =>
        operation.operation === 'addRelatedColumn' &&
        operation.parameters.columns.some(
          (existingColumn) => existingColumn.property.key === column.property.key,
        ),
    ),
  );

export const generateGroupColumnKey = (reference: GroupKeyValue) =>
  `${'modelId' in reference ? `${reference.modelId}_` : ''}${reference.fieldKey}`;

const createAddRelatedColumnOperationsForGroupKeyValues = (
  relations: PipelineStateRelation[],
  groupKeyValues: GroupKeyValue[],
): AddRelatedColumnOperation[] => {
  return compact(
    groupKeyValues.map((groupKeyValue) => {
      if (!('modelId' in groupKeyValue)) {
        return null;
      }

      const relation = relations
        .filter((relation) =>
          getValidRelationTypesForOperation('addRelatedColumn').includes(relation.type),
        )
        .find((relation) => relation.modelId === groupKeyValue.modelId);

      if (!relation) {
        throw new Error(`Failed to find relation for model ${groupKeyValue.modelId}`);
      }

      const columnKey = generateGroupColumnKey(groupKeyValue);

      return {
        operation: 'addRelatedColumn',
        parameters: {
          relation: { key: relation.key, modelId: relation.baseModelId },
          columns: [
            {
              key: groupKeyValue.fieldKey,
              property: {
                key: columnKey,
                name: columnKey,
              },
            },
          ],
        },
      };
    }),
  );
};

export const updateGroupings = (
  funnelOperation: FunnelOperation,
  groups: Grouping[],
  exploration: Exploration,
  ctx: PipelineStateContext,
): FunnelOperation => {
  const groupKeyValues = groups.map(({ key }) => parseGroupKeyValue(key));

  const updatedOperation = {
    ...funnelOperation,
    parameters: {
      ...omit(funnelOperation.parameters, 'groups'),
      ...(groupKeyValues.length > 0
        ? {
            groups: groups.map((group) => ({
              ...group,
              key: generateGroupColumnKey(parseGroupKeyValue(group.key)),
            })),
          }
        : {}),
      steps: funnelOperation.parameters.steps.map((step) => {
        const { relations } = getStepPipelineState(step, exploration, ctx);
        const addRelatedColumnOperations = createAddRelatedColumnOperationsForGroupKeyValues(
          relations,
          groupKeyValues,
        );
        return {
          ...step,
          pipeline: {
            ...step.pipeline,
            operations: [
              ...step.pipeline.operations,
              ...addRelatedColumnOperations.filter(
                (operation) => !addRelatedColumnOperationExists(step.pipeline, operation),
              ),
            ],
          },
        };
      }),
    },
  };
  return removeUnusedRelatedColumns(updatedOperation);
};

/**
 * Determine the select option value of a group key based on the addRelatedColumn operations (if any).
 * This way TTQ does not have to generate the property key in any specific format and we are free
 * to change option value formats.
 */
export const getGroupKeyValue = (
  groupKey: string,
  funnelOperation: FunnelOperation,
  exploration: Exploration,
  ctx: PipelineStateContext,
) => {
  const isMatchingOperation = (operation: PipelineOperation) =>
    operation.operation === 'addRelatedColumn' &&
    operation.parameters.columns.some((column) => column.property.key === groupKey);

  const step = funnelOperation.parameters.steps.find((step) =>
    step.pipeline.operations.some(isMatchingOperation),
  );
  const operation = step?.pipeline.operations.find(isMatchingOperation);
  if (!step || !operation) {
    return serializeGroupKeyValue({
      fieldKey: groupKey,
    });
  }
  if (operation.operation !== 'addRelatedColumn') {
    throw new Error('Unexpected operation');
  }
  const { relations } = getStepPipelineState(step, exploration, ctx);
  const { modelId, key } = operation.parameters.relation;
  const relation = relations.find(
    (relation) =>
      relation.key === key && (modelId === undefined || relation.baseModelId === modelId),
  );
  if (!relation) {
    throw new Error(`Failed to find relation for key ${key}`);
  }
  return serializeGroupKeyValue({
    modelId: relation.modelId,
    fieldKey: operation.parameters.columns[0].key,
  });
};

const getFirstIdField = (fields: Fields) =>
  fields.find((field) => field.pk === true || field.key.endsWith('_id'));

export const getDefaultCountingFieldKey = (fields: Fields, model: Model, models: Model[]) => {
  return (
    fields.find((field) => field.key === getJoinKeyToSemanticModel(model, models, 'User'))?.key ??
    fields.find((field) => field.key === getJoinKeyToSemanticModel(model, models, 'Account'))
      ?.key ??
    getFirstIdField(fields)?.key ??
    fields[0].key
  );
};

export const getDefaultSortFieldKey = (fields: Fields, model: Model) =>
  fields.find((field) => field.key === model.semantics?.properties?.createdAt)?.key ??
  fields.find((field) => field.type === 'Date')?.key ??
  fields[0].key;

export const getEmptyFunnelStep = (): FunnelStep => ({
  sortKey: '',
  fields: [],
  pipeline: {
    baseModelId: '',
    operations: [],
  },
});

export const isEmptyStep = (step: FunnelStep) =>
  !('baseModelId' in step.pipeline && step.pipeline.baseModelId !== '') &&
  !('parentId' in step.pipeline && step.pipeline.parentId !== '');

export const getFunnelOperation = (pipeline: Pipeline) => {
  const operation = last(pipeline.operations);

  if (operation?.operation !== 'funnel') {
    throw new Error('Failed to find funnel operation');
  }

  return removeEmptyFunnelSteps(operation);
};

/**
 * Used to remove empty steps before validation so we don't constantly toggle between
 * funnels and errors while the user is building the funnel.
 */
export const removeEmptyFunnelSteps = (operation: FunnelOperation) => {
  return {
    ...operation,
    parameters: {
      ...operation.parameters,
      steps: operation.parameters.steps.filter((step) => !isEmptyStep(step)),
    },
  };
};
