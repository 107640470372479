import {
  FunctionDefinition,
  T,
  OneOfT,
  OptionalT,
  Token,
  TokenType,
  spliceTokens,
  isOneOfT,
  isOptionalT,
} from '@gosupersimple/penguino';
import { compact, nth } from 'lodash';

import { FieldGroup, isField, isFieldGroup } from '@/explore/pipeline/utils';
import { Field } from '@/explore/types';

interface CaretContext {
  tokens: Token[];
  caretPosition: number;
  currentTokenIndex: number;
  currentToken: Token;
  outerFunctionCallIndex: number;
  outerFunction: FunctionDefinition | undefined;
  searchTermIndex: number;
  searchTerm: string;
  fields: (Field | FieldGroup)[];
  functions: FunctionDefinition[];
}

export const getTokenIndexAt = <T extends { start: number; end: number }>(
  tokens: T[],
  position: number,
) => tokens.findIndex((token) => token.start <= position && token.end >= position);

export const getOuterFunctionCallIndex = <
  T extends { start: number; end: number; type: TokenType; lexeme: string },
>(
  tokens: T[],
  caretPosition: number,
) => {
  const caretTokenIndex = getTokenIndexAt(tokens, caretPosition);
  const lastOpenParenIndex = getLastOpenParenIndex(tokens, caretTokenIndex + 1);
  if (lastOpenParenIndex === -1) {
    return -1;
  }
  const token = nth(tokens, lastOpenParenIndex - 1);
  return token?.type === TokenType.Identifier ? lastOpenParenIndex - 1 : -1;
};

export const getLastOpenParenIndex = <T extends { start: number; end: number; type: TokenType }>(
  tokens: T[],
  atIndex: number,
): number => {
  if (atIndex < 0) {
    return -1;
  }
  const prevTokens = tokens.slice(0, atIndex);
  const lastLeftParenIndex = prevTokens.findLastIndex(
    (token) => token.type === TokenType.LeftParen,
  );
  const lastRightParenIndex = prevTokens.findLastIndex(
    (token) => token.type === TokenType.RightParen,
  );
  if (lastLeftParenIndex !== -1 && lastRightParenIndex > lastLeftParenIndex) {
    return getLastOpenParenIndex(tokens, getLastOpenParenIndex(tokens, lastRightParenIndex));
  }
  return lastLeftParenIndex;
};

/**
 * Return the token index of what should be the "search term" for the autocomplete.
 * It is either
 * - The identifier token under the caret
 * - The string token under the caret if the caret is within a prop call
 */
const getSearchTermIndex = (
  currentTokenIndex: number,
  currentToken: Token,
  outerFunctionName: string | undefined,
) => {
  const isCurrentTokenIdentifier = currentToken.type === TokenType.Identifier;
  return outerFunctionName === 'prop'
    ? currentTokenIndex
    : isCurrentTokenIdentifier
      ? currentTokenIndex
      : -1;
};

const getSearchTermFromToken = (tokens: Token[], searchTermIndex: number) => {
  const token = nth(tokens, searchTermIndex);
  return token?.type === TokenType.Identifier ? (token?.lexeme ?? '') : '';
};

const filterItemsByName = <T extends { name: string }>(items: T[], searchTerm: string) => {
  if (searchTerm === '') {
    return items;
  }
  return items.filter((item) =>
    item.name
      .replaceAll(' ', '')
      .toLocaleLowerCase()
      .includes(searchTerm.replaceAll(' ', '').toLocaleLowerCase()),
  );
};

export const createCaretContext = (
  tokens: Token[],
  caretPosition: number,
  fields: (Field | FieldGroup)[],
  functions: FunctionDefinition[],
) => {
  const currentTokenIndex = getTokenIndexAt(tokens, caretPosition);
  const currentToken = nth(tokens, currentTokenIndex);
  if (currentToken === undefined) {
    throw new Error('Caret position is out of bounds');
  }
  const outerFunctionCallIndex = getOuterFunctionCallIndex(tokens, caretPosition);
  const outerFunctionName = nth(tokens, outerFunctionCallIndex)?.lexeme;
  const searchTermIndex = getSearchTermIndex(currentTokenIndex, currentToken, outerFunctionName);
  // TODO: searchTerm is filtered to only identifiers but searchTermIndex does not check token type
  return {
    tokens,
    caretPosition,
    currentTokenIndex,
    currentToken,
    searchTermIndex,
    searchTerm: getSearchTermFromToken(tokens, searchTermIndex),
    outerFunctionCallIndex,
    outerFunction: functions.find((fn) => fn.name === outerFunctionName),
    fields,
    functions,
  };
};

const getFilteredFieldItems = (
  fields: (Field | FieldGroup)[],
  searchTerm: string,
): FieldGroup[] => {
  return [
    ...fields.filter(isFieldGroup).map((group) => {
      return {
        name: group.name,
        fields: filterItemsByName(group.fields, searchTerm),
      };
    }),
    {
      // Ungrouped fields
      fields: filterItemsByName(fields.filter(isField), searchTerm),
    },
  ];
};

export const getFilteredAutoCompleteItems = (context: CaretContext) => {
  const { searchTerm, outerFunction, fields, functions } = context;
  switch (outerFunction?.name) {
    case 'prop':
      return {
        fields: getFilteredFieldItems(fields, searchTerm),
        functions: [],
      };
    default:
      return {
        fields: getFilteredFieldItems(fields, searchTerm),
        functions: filterItemsByName(functions, searchTerm),
      };
  }
};

const createCompletionForField = (
  context: CaretContext,
  item: { name: string; type: 'field' },
): [Token[], number] => {
  const {
    caretPosition,
    currentTokenIndex,
    currentToken,
    searchTerm,
    searchTermIndex,
    outerFunction,
  } = context;
  const tokens = [...context.tokens];
  const isWithinPropCall = outerFunction?.name === 'prop';
  const addClosingParen =
    !isWithinPropCall || nth(tokens, searchTermIndex + 1)?.type !== TokenType.RightParen;
  const replaceSearchTerm = searchTerm !== '';
  const replaceCurrentToken = isWithinPropCall && currentToken?.type === TokenType.String;
  const insertPosition =
    searchTermIndex === -1
      ? currentTokenIndex + 1
      : searchTermIndex + (replaceSearchTerm || replaceCurrentToken ? 0 : 1);

  const newTokens = compact([
    !isWithinPropCall ? { type: TokenType.Identifier, lexeme: 'prop' } : null,
    !isWithinPropCall ? { type: TokenType.LeftParen, lexeme: '(' } : null,
    { type: TokenType.String, lexeme: `"${item.name}"`, literal: item.name },
    addClosingParen ? { type: TokenType.RightParen, lexeme: ')' } : null,
  ]);
  const totalLength = newTokens.reduce((acc, token) => acc + token.lexeme.length, 0);

  const newCaretPosition =
    caretPosition +
    totalLength +
    (isWithinPropCall && !addClosingParen ? 1 : 0) -
    (replaceSearchTerm ? caretPosition - currentToken.start : 0) -
    (replaceCurrentToken ? caretPosition - currentToken.start : 0);

  spliceTokens(
    tokens,
    insertPosition,
    replaceSearchTerm || replaceCurrentToken ? 1 : 0,
    ...newTokens,
  );
  return [tokens, newCaretPosition];
};

const createCompletionForFunction = (
  context: CaretContext,
  item: { name: string; type: 'function' },
): [Token[], number] => {
  const { caretPosition, currentTokenIndex, searchTerm, searchTermIndex } = context;
  const tokens = [...context.tokens];
  const nextToken = nth(tokens, searchTermIndex + 1);
  const addParens = nextToken?.type !== TokenType.LeftParen;
  const replaceSearchTerm = searchTerm !== '';
  const insertPosition =
    searchTermIndex === -1 ? currentTokenIndex + 1 : searchTermIndex + (replaceSearchTerm ? 0 : 1);

  const newTokens = compact([
    { type: TokenType.Identifier, lexeme: item.name },
    addParens ? { type: TokenType.LeftParen, lexeme: '(' } : null,
    addParens ? { type: TokenType.RightParen, lexeme: ')' } : null,
  ]);
  const totalLength = newTokens.reduce((acc, token) => acc + token.lexeme.length, 0);

  const newCaretPosition =
    caretPosition +
    totalLength -
    (addParens ? 1 : 0) -
    (replaceSearchTerm ? (searchTerm.length ?? 0) : 0);

  spliceTokens(tokens, insertPosition, replaceSearchTerm ? 1 : 0, ...newTokens);
  return [tokens, newCaretPosition];
};

export const createCompletion = (
  context: CaretContext,
  item: { name: string; type: 'field' } | { name: string; type: 'function' },
): [Token[], number] => {
  return item.type === 'field'
    ? createCompletionForField(context, item)
    : createCompletionForFunction(context, item);
};

export const invalidArgumentErrorMessage = (arg: {
  received: string | null;
  expected: T | OneOfT | OptionalT | null;
}) =>
  arg.received === null
    ? `Expected ${renderType(arg.expected)}`
    : `Received ${arg.received}, expected ${renderType(arg.expected)}`;

const renderType = (type: T | OneOfT | OptionalT | null): string => {
  if (isOneOfT(type)) {
    return type.$oneOf.join(', ');
  }
  if (isOptionalT(type)) {
    return renderType(type.$optional) + '?';
  }
  if (type === null) {
    return 'nothing';
  }

  return type;
};
