import { useState } from 'react';

import { DropDownButton, InlineButton } from '@/components/button';
import { Dropdown } from '@/components/dropdown';
import { Icon } from '@/components/icon';
import { useAccountContext } from '@/lib/accounts/context';
import { ConfirmationModal } from '@/components/confirmation-modal';
import { useToastContext } from '@/components/toast';
import { useTrackEvent } from '@/lib/analytics';
import { getMessage } from '@/lib/error';

import { ExplorationCreateModal } from '../create-modal';
import { ExplorationEditModal } from '../edit-modal';
import { useExplorationContext } from '../exploration-context';
import { Exploration } from '../../types';
import {
  isModelDetailExploration,
  generateExplorationId,
  modelDetailExplorationToExploration,
} from '../../utils';
import { CreateTemplateModal } from './create-template-modal';

import formStyles from '@/components/form/form.module.scss';
import styles from './settings.module.scss';

interface ExplorationSettingsProps {
  exploration: Exploration;
  updateDisabled?: boolean;
}

export const ExplorationSettings = ({
  exploration,
  updateDisabled = false,
}: ExplorationSettingsProps) => {
  const { isDirty, parameters, upsertExploration, deleteExploration } = useExplorationContext();
  const addToast = useToastContext();
  const trackEvent = useTrackEvent();
  const { hasPermission } = useAccountContext();
  const manageExplorationsPermitted = hasPermission('MANAGE_EXPLORATIONS');

  const [showEditConfirmationModal, setShowEditConfirmationModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);
  const [showCreateDetailModal, setShowCreateDetailModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleUpdate = async (data?: { name: string; description?: string }) => {
    try {
      const updatedExploration = { ...exploration, ...data };

      const nextExploration = await upsertExploration(updatedExploration, parameters);
      setShowEditModal(false);
      setShowEditConfirmationModal(false);

      trackEvent(data === undefined ? 'Exploration Saved' : 'Exploration Details Updated', {
        explorationId: nextExploration.explorationId,
        explorationSourceId: exploration.options?.explorationSourceId,
        previousExplorationId: exploration.explorationId,
        name: exploration.name,
        details: data,
      });

      addToast({
        title: 'Success',
        content: () => `Exploration updated successfully.`,
        kind: 'success',
      });
    } catch (e) {
      addToast({
        title: 'Error',
        content: () => `Updating exploration failed: ${getMessage(e)}`,
        kind: 'error',
      });
    }
  };

  const handleCreate = async (data?: { name: string; description?: string }) => {
    try {
      const newExploration = {
        ...exploration,
        ...data,
        options: {},
        explorationId: generateExplorationId(),
      };

      await upsertExploration(newExploration, parameters);

      setShowCreateModal(false);

      trackEvent('Exploration Saved As New', {
        explorationId: newExploration.explorationId,
        name: newExploration.name,
      });

      addToast({
        title: 'Success',
        content: () => `Exploration created successfully.`,
        kind: 'success',
      });
    } catch (e) {
      addToast({
        title: 'Error',
        content: () => `Creating exploration failed: ${getMessage(e)}`,
        kind: 'error',
      });
    }
  };

  const handleSaveTemplate = async () => {
    try {
      await upsertExploration(exploration, parameters);

      setShowCreateTemplateModal(false);

      trackEvent('Exploration Template Saved', {
        explorationid: exploration.explorationId,
        name: exploration.name,
      });

      addToast({
        title: 'The template has been saved',
        content: () => `You can view the changes in all the detail views of this data model`,
        kind: 'success',
      });
    } catch (e) {
      addToast({
        title: 'Error',
        content: () => `Creating detail view of data model failed: ${getMessage(e)}`,
        kind: 'error',
      });
    }
  };

  const handleCreateDetail = async (data?: { name: string; description?: string }) => {
    try {
      const newExploration = {
        ...modelDetailExplorationToExploration(exploration, parameters),
        ...data,
        explorationId: generateExplorationId(),
      };

      await upsertExploration(newExploration, {});

      setShowCreateDetailModal(false);

      trackEvent('Exploration template saved as detail exploration', {
        explorationId: newExploration.explorationId,
        name: newExploration.name,
      });

      addToast({
        title: 'Success',
        content: () => `Exploration created successfully.`,
        kind: 'success',
      });
    } catch (e) {
      addToast({
        title: 'Error',
        content: () => `Creating exploration failed: ${getMessage(e)}`,
        kind: 'error',
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteExploration(exploration.explorationId);

      trackEvent('Exploration Deleted', {
        explorationId: exploration.explorationId,
        name: exploration.name,
      });

      addToast({
        title: 'Success',
        content: () => `Exploration deleted successfully.`,
        kind: 'success',
      });
    } catch (e) {
      addToast({
        title: 'Error',
        content: () => `Deleting exploration failed: ${getMessage(e)}`,
        kind: 'error',
      });
    }
  };

  if (!manageExplorationsPermitted) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.description}>
          <p>
            <b>Save for everyone</b>
          </p>
          <p>
            Only Editors can manage saved explorations. Contact your company admin about user
            permissions.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.description}>
        {exploration.description !== undefined && (
          <>
            <p>
              <strong>Name</strong>
              <br />
              {exploration.name}
            </p>
            <p>
              <strong>Description</strong>
              <br />
              {exploration.description}
            </p>

            <hr className={formStyles.dashed} />
          </>
        )}

        <p>
          <b>Save for everyone</b>
        </p>
        {isModelDetailExploration(exploration) && (
          <p>
            Saving changes to the details view template will apply to all the details views in this
            data model.
          </p>
        )}
        <p>
          <b>Everyone</b> on your team can access saved explorations.
        </p>
      </div>
      {isModelDetailExploration(exploration) ? (
        <ol className={styles.actions}>
          <li>
            <Dropdown
              align="left"
              menuClassName={styles.dropDown}
              trigger={(isOpen, setIsOpen) => (
                <DropDownButton
                  className={styles.dropDownButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(!isOpen);
                  }}>
                  Save as …
                </DropDownButton>
              )}
              items={[
                {
                  label: 'Save changes to data template',
                  icon: <Icon name="Save" size={16} />,
                  onClick: () => setShowCreateTemplateModal(true),
                },
                {
                  label: 'Save as exploration',
                  icon: <Icon name="Duplicate" size={16} />,
                  onClick: () => setShowCreateDetailModal(true),
                },
              ]}
            />
          </li>
        </ol>
      ) : (
        <ol className={styles.actions}>
          {updateDisabled ? null : isDirty ? (
            <li>
              <InlineButton onClick={() => setShowEditConfirmationModal(true)}>
                <Icon name="Save" size={16} /> {`Save changes to "${exploration.name}"`}
              </InlineButton>
            </li>
          ) : (
            <li>
              <InlineButton onClick={() => setShowEditModal(true)}>
                <Icon name="Edit3" size={16} /> Edit details
              </InlineButton>
            </li>
          )}
          <li>
            <InlineButton onClick={() => setShowCreateModal(true)}>
              <Icon name="Copy" size={16} /> Save as a new exploration
            </InlineButton>
          </li>
        </ol>
      )}
      {showEditConfirmationModal && (
        <ConfirmationModal
          title={`Save changes to "${exploration.name}"`}
          description="This will publish changes to everyone in your team."
          submitLabel="Save"
          onSubmit={handleUpdate}
          onClose={() => setShowEditConfirmationModal(false)}
        />
      )}
      {showEditModal && (
        <ExplorationEditModal
          exploration={exploration}
          onSubmit={handleUpdate}
          onClose={() => setShowEditModal(false)}
          onDelete={() => {
            setShowEditModal(false);
            setShowDeleteModal(true);
          }}
        />
      )}
      {showCreateModal && (
        <ExplorationCreateModal onCreate={handleCreate} onClose={() => setShowCreateModal(false)} />
      )}
      {showCreateTemplateModal && (
        <CreateTemplateModal
          modelName={exploration.name}
          onCreate={handleSaveTemplate}
          onClose={() => setShowCreateTemplateModal(false)}
        />
      )}
      {showCreateDetailModal && (
        <ExplorationCreateModal
          onCreate={handleCreateDetail}
          onClose={() => setShowCreateDetailModal(false)}
        />
      )}
      {showDeleteModal && (
        <ConfirmationModal
          title={`Are you sure you want to delete "${exploration.name}"?`}
          submitLabel="Delete"
          onSubmit={handleDelete}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
};
