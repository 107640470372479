import { ScaleLinear, ScaleTime } from 'd3-scale';

import { BarRounded } from '@visx/shape';
import { Group } from '@visx/group';

type BarSeriesProps = {
  data: { date: Date; value: number }[];
  fillColor: string;
  valueScale: ScaleLinear<number, number>;
  dateScale: ScaleTime<number, number>;
  barRadius?: number;
  barWidth?: number;
  x?: number;
  y?: number;
};

export const BarSeries = (props: BarSeriesProps) => {
  const { data, fillColor, valueScale, dateScale, barWidth = 10, barRadius = 5 } = props;
  return (
    <Group>
      {data.map(({ date, value }, idx) => {
        const y = (valueScale(value) ?? 0) + (props.y ?? 0);
        const x = (dateScale(date) ?? 0) + (props.x ?? 0);

        const height = valueScale.range()[0] - y;
        return (
          <BarRounded
            key={idx}
            x={x}
            y={y}
            width={barWidth}
            height={height}
            fill={fillColor}
            radius={barRadius}
            top
          />
        );
      })}
    </Group>
  );
};
