import { ScaleLinear, ScaleTime } from 'd3-scale';

import { formatNumberToCompact } from '@/lib/utils/number';

import styles from './series-values.module.scss';

const ChartEdgePadding = 4;

type LineSeriesProps = {
  data: { date: Date; value: number }[];
  valueColor: string;
  valueScale: ScaleLinear<number, number>;
  dateScale: ScaleTime<number, number>;
  x?: number;
  y?: number;
  avoidOverflow?: boolean;
};

export const SeriesValues = (props: LineSeriesProps) => {
  const { data, valueColor, valueScale, dateScale, avoidOverflow = false } = props;

  return data.map((d, i) => {
    const isFirst = i === 0;
    const isLast = i === data.length - 1;
    const textAnchor =
      avoidOverflow && isFirst ? 'start' : avoidOverflow && isLast ? 'end' : 'middle';
    const dx =
      avoidOverflow && isFirst ? ChartEdgePadding : avoidOverflow && isLast ? -ChartEdgePadding : 0;
    return (
      <text
        key={i}
        className={styles.valueLabel}
        x={dateScale(d.date) ?? 0}
        y={valueScale(d.value) ?? 0}
        dx={dx + (props.x ?? 0)}
        dy={-8 + (props.y ?? 0)}
        fill={valueColor}
        style={{ textAnchor }}>
        {formatNumberToCompact({ num: d.value })}
      </text>
    );
  });
};
