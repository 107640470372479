import {
  CSSProperties,
  useState,
  useRef,
  MouseEventHandler,
  forwardRef,
  useEffect,
  Ref,
} from 'react';
import classNames from 'classnames';
import { useClickAnyWhere } from 'usehooks-ts';
import { Link } from 'react-router-dom';

import { useClientRect } from '@/lib/hooks/use-client-rect';

import { useKeyPress } from '@/lib/hooks/use-key-press';

import { CheckboxWithLabel } from '../form/checkbox';
import { Overlay } from '../overlay';

import { RadioButton } from '../form/radio-button';

import { ColorPicker } from '../form/color-input';

import styles, { dropdownTriggerGap } from './dropdown.module.scss';

interface DropdownMenuItemActionable {
  label: string | React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  onMouseOver?: MouseEventHandler;
  onMouseDown?: MouseEventHandler;
  className?: classNames.Argument;
  color?: string;
  focused?: boolean;
}

interface DropdownMenuItemButton extends DropdownMenuItemActionable {
  type?: 'button';
}

interface DropdownMenuItemLink extends DropdownMenuItemActionable {
  type: 'link';
  href: string;
  external?: boolean;
}

interface DropdownMenuItemCheckbox extends DropdownMenuItemActionable {
  type: 'checkbox';
  checked?: boolean;
  onChange: (isChecked: boolean) => void;
}

interface DropdownMenuItemRadio extends DropdownMenuItemActionable {
  type: 'radio';
  checked?: boolean;
}

interface DropdownMenuItemColor extends DropdownMenuItemActionable {
  type: 'color';
  label: string;
  color: string;
  presetColors?: string[];
  onChange: (color: string) => void;
}

export interface DropdownMenuItemDivider {
  type: 'divider';
}

export interface DropdownMenuItemHeader {
  type: 'header';
  label: string;
}

export type DropdownMenuItem =
  | DropdownMenuItemButton
  | DropdownMenuItemLink
  | DropdownMenuItemCheckbox
  | DropdownMenuItemRadio
  | DropdownMenuItemDivider
  | DropdownMenuItemHeader
  | DropdownMenuItemColor;

type DropdownProps = {
  items: DropdownMenuItem[];
  trigger: (isOpen: boolean, setIsOpen: (isOpen: boolean) => void) => React.ReactNode;
  align: 'left' | 'right' | 'both';
  animated?: boolean;
  className?: classNames.Argument;
  menuClassName?: classNames.Argument;
  style?: CSSProperties;
};

export const Dropdown = (props: DropdownProps) => {
  const { items, trigger, align, className, style, animated = true } = props;
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLDivElement>(null);
  const triggerRect = useClientRect(triggerRef, [isOpen]);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownRect = useClientRect(dropdownRef, [isOpen]);

  useClickAnyWhere((e) => {
    if (!isOpen || (triggerRef.current?.contains(e.target as Node) ?? false)) {
      return;
    }
    setIsOpen(false);
  });

  useKeyPress('Escape', (e: KeyboardEvent) => {
    if (isOpen) {
      e.preventDefault();
      setIsOpen(false);
    }
  });

  const handleMenuClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  const shouldOpenAbove = () =>
    getLowerAnchorY() + getDropdownHeight() > window.innerHeight &&
    getUpperAnchorY() - getDropdownHeight() > 0;

  const getLowerAnchorY = () => triggerRect?.bottom ?? 0;
  const getUpperAnchorY = () => triggerRect?.top ?? 0;
  const getAnchorY = () => (shouldOpenAbove() ? getUpperAnchorY() : getLowerAnchorY());

  const getDropdownHeight = () => dropdownRect?.height ?? 0;
  const getViewportHeight = () => window.innerHeight - parseInt(dropdownTriggerGap) * 2;

  // When positioning below the trigger, we shift the dropdown up if it would overflow the viewport
  const getYOverflow = () => Math.max(0, getAnchorY() + getDropdownHeight() - getViewportHeight());

  const getUpperY = () => getUpperAnchorY() - getDropdownHeight();
  const getLowerY = () => Math.max(0, getAnchorY() - getYOverflow());

  const dropdownPos =
    triggerRect === null || dropdownRect === null
      ? undefined
      : {
          top: shouldOpenAbove() ? getUpperY() : getLowerY(),
          left: align === 'right' ? triggerRect.right - dropdownRect.width : triggerRect.left,
          minWidth: align === 'both' ? triggerRect.width : 'auto',
        };

  return (
    <div
      className={classNames(styles.dropdown, className, {
        [styles.dropdownOpen]: isOpen,
        [styles.openAbove]: shouldOpenAbove(),
      })}
      style={style}>
      <div ref={triggerRef}>{trigger(isOpen, setIsOpen)}</div>

      {isOpen && (
        <Overlay>
          <div onClick={handleMenuClick}>
            <DropdownMenu
              ref={dropdownRef}
              items={items}
              animated={animated}
              className={props.menuClassName}
              style={dropdownPos}
            />
          </div>
        </Overlay>
      )}
    </div>
  );
};

export const DropdownMenu = forwardRef(function DropdownMenu(
  {
    items,
    className,
    style,
    animated,
  }: {
    items: DropdownMenuItem[];
    className?: classNames.Argument;
    style?: CSSProperties;
    animated?: boolean;
  },
  ref?: Ref<HTMLDivElement>,
) {
  const focusedItemIndex = items.findIndex((item) => 'focused' in item && item.focused === true);
  const focusedItemRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null);

  useEffect(() => {
    focusedItemRef.current?.scrollIntoView({ block: 'nearest' });
  }, [focusedItemIndex]);

  return (
    <div
      ref={ref}
      className={classNames(styles.dropdownMenu, { [styles.animated]: animated }, className)}
      style={style}>
      {items.map((item, idx) => {
        return (
          <DropdownMenuItem
            key={idx}
            item={item}
            ref={'focused' in item && item.focused === true ? focusedItemRef : null}
          />
        );
      })}
    </div>
  );
});

const DropdownMenuItem = forwardRef(function DropdownMenuItem(
  { item }: { item: DropdownMenuItem },
  ref: React.Ref<HTMLButtonElement | HTMLAnchorElement>,
) {
  if (item.type === 'divider') {
    return <hr className={styles.divider} />;
  }

  if (item.type === 'header') {
    return <p className={styles.header}>{item.label}</p>;
  }

  const sharedProps = {
    className: classNames(styles.item, item.className, {
      [styles.disabled]: item.disabled,
      [styles.focused]: item.focused === true,
    }),
    style: { color: item.color },
    onClick: (e: React.MouseEvent) => item.onClick?.(e),
    onMouseOver: item.onMouseOver,
    onMouseDown: item.onMouseDown,
    tabIndex: -1,
  };

  switch (item.type) {
    case 'link':
      return (
        <Link
          {...sharedProps}
          to={item.href}
          {...(item.external === true && { target: '_blank', rel: 'noopener noreferrer' })}
          ref={ref as React.Ref<HTMLAnchorElement>}>
          {item.icon !== undefined && item.icon}
          {item.label}
        </Link>
      );
    case 'checkbox':
      return (
        <CheckboxWithLabel
          {...sharedProps}
          ref={ref as React.Ref<HTMLLabelElement>}
          checked={item.checked}
          onChange={({ checked }) => item.onChange?.(checked)}
          onClick={(e) => e.stopPropagation()}>
          {item.icon !== undefined && item.icon}
          {item.label}
        </CheckboxWithLabel>
      );
    case 'radio':
      return (
        <div
          className={sharedProps.className}
          onClick={(e) => {
            e.stopPropagation();
            item.onClick?.(e);
          }}>
          <RadioButton
            name={typeof item.label === 'string' ? item.label : ''}
            label={item.label}
            isChecked={item.checked}
          />
        </div>
      );
    case 'color':
      return (
        <div
          className={sharedProps.className}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
          }}>
          <ColorPicker
            color={item.color}
            label={item.label}
            size="small"
            presetColors={item.presetColors}
            onChange={item.onChange}
          />
        </div>
      );
    default:
      return (
        <button
          {...sharedProps}
          type="button"
          disabled={item.disabled}
          ref={ref as React.Ref<HTMLButtonElement>}>
          {item.icon !== undefined && item.icon}
          {item.label}
        </button>
      );
  }
});
