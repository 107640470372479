import { first } from 'lodash';

import { pluralize } from '@/lib/utils/string';
import { getModelOrThrow } from '@/explore/model/utils';
import { getCellByPipelineIdOrThrow } from '@/explore/exploration/utils';

import {
  Cell,
  Exploration,
  JoinPipelineOperation,
  Model,
  Pipeline,
  PipelineOperation,
} from '../../types';

export const getOperationTitle = (operation: PipelineOperation) => {
  switch (operation.operation) {
    case 'invalid':
      return 'Invalid operation';
    case 'filter':
      return 'Filter';
    case 'relationAggregate':
      return `Summarize Related Data ${'relationKey' in operation.parameters ? `(${operation.parameters.relationKey})` : ''}`;
    case 'deriveField':
      return 'Custom Formula';
    case 'addRelatedColumn':
      return `Column from ${operation.parameters.relation.key}`;
    case 'groupAggregate':
      return 'Summarize';
    case 'sql':
      return 'SQL Query';
    case 'switchToRelation':
      return 'Jump to related data';
    case 'timeSeries':
      return 'Time Series';
    default:
      return '';
  }
};

export const isJoinedPipeline = (pipeline: Pipeline) =>
  first(pipeline.operations)?.operation === 'joinPipeline';

export const getPipelineTitle = (models: Model[], exploration: Exploration, pipeline: Pipeline) => {
  if ('baseModelId' in pipeline) {
    return getModelOrThrow(models, pipeline.baseModelId).name;
  }

  if ('parentId' in pipeline) {
    const parentCell = getCellByPipelineIdOrThrow(pipeline.parentId, exploration);
    if (parentCell.kind === 'records') {
      return parentCell.title ?? '';
    }
  }

  return '';
};

export const getJoinedPipelineTitle = (
  pipeline: Pipeline,
  operation: JoinPipelineOperation,
  models: Model[],
  exploration: Exploration,
) => {
  return `${getPipelineTitle(models, exploration, pipeline)} + ${getPipelineTitle(
    models,
    exploration,
    operation.parameters.pipeline,
  )}`;
};

const toTitleCase = (str: string) => {
  return str
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
};

export const formatModelLabels = (labels: Model['labels']) => {
  return Object.entries(labels)
    .map(([key, value]) => `${toTitleCase(key)}: ${value}`)
    .join('\n');
};

export const formatCellInstances = (instances: Cell[]) => {
  return `Pipeline used in ${instances.length} ${pluralize(
    instances.length,
    'instance',
    'instances',
  )}:\n${instances
    .map((instance) => ('title' in instance ? `- ${instance.title}` : '- Untitled Cell'))
    .join('\n')}`;
};

export const getRelatedFieldName = (name: string, relationOrModelName: string) => {
  return `${name} (on ${relationOrModelName})`;
};
