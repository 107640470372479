import { first } from 'lodash';

import { common } from '@gosupersimple/types';

import { PipelineEditorError } from '../edit-pipeline/errors';
import {
  Aggregation,
  AggregationExtendedType,
  AggregationType,
  Field,
  Fields,
  Metric,
  Property,
  aggregationZod,
} from '../types';
import { fieldToOption, nameToKey } from '../edit-pipeline/utils';
import { metricById } from './metrics';

export interface AggregationOption {
  label: string;
  value: AggregationExtendedType;
  kind: 'key' | 'empty' | 'expr';
  fieldTypes?: string[];
}

export const AggregationOptions: AggregationOption[] = [
  { label: 'Count', value: 'count', kind: 'empty' },
  { label: 'Sum', value: 'sum', kind: 'key', fieldTypes: ['Number', 'Integer', 'Float'] },
  { label: 'Count Distinct', value: 'count_distinct', kind: 'key' },
  { label: 'Minimum', value: 'min', kind: 'key', fieldTypes: ['Number', 'Integer', 'Float'] },
  { label: 'Maximum', value: 'max', kind: 'key', fieldTypes: ['Number', 'Integer', 'Float'] },
  { label: 'Earliest', value: 'earliest', kind: 'key', fieldTypes: ['Date'] },
  { label: 'Latest', value: 'latest', kind: 'key', fieldTypes: ['Date'] },
  { label: 'First Value', value: 'first', kind: 'key' },
  { label: 'Last Value', value: 'last', kind: 'key', fieldTypes: ['Number', 'Integer', 'Float'] }, // Only for timeSeries
  { label: 'Average', value: 'avg', kind: 'key', fieldTypes: ['Number', 'Integer', 'Float'] },
  { label: 'Median', value: 'median', kind: 'key', fieldTypes: ['Number', 'Integer', 'Float'] },
  { label: 'Custom Formula', value: 'custom', kind: 'expr' },
];

const getFieldsForAggregationType = (type: AggregationExtendedType, fields: Fields) => {
  const aggregationOption = AggregationOptions.find((a) => a.value === type);

  return aggregationOption === undefined
    ? []
    : fields.filter(({ type }) => aggregationOption.fieldTypes?.includes(type ?? '') ?? true);
};

export const getFieldsForAggregation = (aggregation: Aggregation, fields: Fields) =>
  getFieldsForAggregationType(
    aggregationTypeToAggregationExtendedType(
      aggregation.type,
      fields.find((f) => f.key === aggregation.key),
    ),
    fields,
  );

export const getFieldOptionsForAggregationType = (type: AggregationExtendedType, fields: Fields) =>
  getFieldsForAggregationType(type, fields).map(fieldToOption);

export const getAggregationOptionsForFields = (
  fields: Fields,
  aggregationOptions = AggregationOptions,
) => {
  return aggregationOptions.filter(({ fieldTypes }) =>
    fields.some(({ type }) => fieldTypes?.includes(type ?? '') ?? true),
  );
};

/**
 * Convert a normal aggregation type to an extended aggregation type for display.
 * The extended type includes types like `earlier` and `latest` that are converted back
 * into `min` and `max` for the backend.
 * Whether a type `min` should be `min` or `earlier` is determined by the type of the
 * field referenced by the aggregation.
 */
export const aggregationTypeToAggregationExtendedType = (
  aggregationType: AggregationType,
  field?: Field,
) =>
  AggregationOptions.find(
    (option) =>
      aggregationExtendedTypeToAggregationType(option.value) === aggregationType &&
      (option.fieldTypes === undefined || option.fieldTypes.includes(field?.type ?? '')),
  )?.value ?? aggregationType;

/**
 * Convert an extended aggregation type to a normal aggregation type for backend.
 */
export const aggregationExtendedTypeToAggregationType = (extendedType: AggregationExtendedType) => {
  switch (extendedType) {
    case 'earliest':
      return 'min';
    case 'latest':
      return 'max';
    default:
      return extendedType;
  }
};

export const getAggregationLabel = (type: AggregationExtendedType, field?: Field) => {
  const fieldName = field?.name ?? 'Unknown';
  switch (type) {
    case 'count':
      return 'Count';
    case 'count_distinct':
      return `Count of ${fieldName} (distinct)`;
    case 'sum':
      return `Sum of ${fieldName}`;
    case 'min':
      return `Min ${fieldName}`;
    case 'max':
      return `Max ${fieldName}`;
    case 'earliest':
      return `Earliest ${fieldName}`;
    case 'latest':
      return `Latest ${fieldName}`;
    case 'avg':
      return `Average ${fieldName}`;
    case 'median':
      return `Median ${fieldName}`;
    case 'first':
      return `First Value of ${fieldName}`;
    case 'last':
      return `Last Value of ${fieldName}`;
    case 'custom':
      return 'Custom Formula';
    default:
      return 'Unknown Value';
  }
};

export const getDefaultAggregateName = (
  aggregation: Pick<Aggregation, 'type' | 'key' | 'metricId'>,
  fields: Field[],
  metrics: Metric[],
  relationName?: string,
) => {
  const targetField =
    'key' in aggregation ? fields.find((field) => field.key === aggregation.key) : undefined;
  switch (aggregation.type) {
    case 'count':
      return relationName === undefined ? 'Count' : `Number of ${relationName}`;
    case 'metric':
      if (aggregation.metricId === undefined) {
        return 'Invalid metric';
      }
      return metricById(metrics, aggregation.metricId)?.name ?? 'Invalid metric';

    default:
      return `${getAggregationLabel(aggregation.type, targetField)}${
        relationName !== undefined ? ` (${relationName})` : ''
      }`;
  }
};

export const generateAggregationProperty = (
  aggregation: Pick<Aggregation, 'type' | 'key' | 'metricId'>,
  fields: Field[],
  metrics: Metric[],
  relationName?: string,
): Property => {
  const field = fields.find((f) => f.key === aggregation.key);
  return {
    key: field !== undefined ? `${aggregation.type}_${field.key}` : aggregation.type,
    name: getDefaultAggregateName(aggregation, fields, metrics, relationName),
  };
};

export const getDefaultAggregation = (
  fields: Fields,
  metrics: Metric[],
  relationName?: string,
  allowedAggregationOptions = AggregationOptions,
): Aggregation => {
  const aggregationOptions = getAggregationOptionsForFields(fields, allowedAggregationOptions);
  if (fields.length === 0) {
    throw new PipelineEditorError('No fields available for aggregation');
  }

  const aggregationOption =
    aggregationOptions.find(({ fieldTypes }) =>
      fields.some(
        (field) =>
          fieldTypes === undefined || (field.type !== 'Date' && fieldTypes.includes(field.type)),
      ),
    ) ??
    first(aggregationOptions) ??
    first(AggregationOptions);

  if (aggregationOption === undefined) {
    throw new PipelineEditorError('No aggregation options available');
  }

  const firstField = fields.find(
    (field) => aggregationOption.fieldTypes?.includes(field.type ?? '') ?? true,
  );

  const type = aggregationExtendedTypeToAggregationType(aggregationOption.value);
  const key = aggregationOption.kind === 'key' ? firstField?.key : undefined;
  const value = aggregationOption.kind === 'expr' ? { expression: '', version: '1' } : undefined;
  const sort: common.Sorting[] | undefined =
    aggregationOption.value === 'first' || aggregationOption.value === 'last'
      ? [
          {
            key: fields.find(({ type }) => type === 'Date')?.key ?? firstField?.key ?? '',
            direction: 'ASC',
          },
        ]
      : undefined;

  const aggregation = {
    type,
    ...(key !== undefined ? { key } : {}),
    ...(value !== undefined ? { value } : {}),
    ...(sort !== undefined ? { sort } : {}),
  };

  return {
    ...aggregation,
    property: generateAggregationProperty(aggregation, fields, metrics, relationName),
  };
};

export const ensureValidAggregation = (
  aggregation: Aggregation,
  fields: Field[],
  metrics: Metric[],
  relationName?: string,
): Aggregation => {
  const validation = aggregationZod.safeParse(aggregation);

  if (!validation.success) {
    return getDefaultAggregation(fields, metrics, relationName);
  }

  aggregation = validation.data;

  if (aggregation.type === 'count') {
    return {
      type: 'count',
      property: aggregation.property,
    };
  }

  if (aggregation.type === 'custom') {
    return {
      type: 'custom',
      value: aggregation.value,
      property: aggregation.property,
    };
  }

  if (aggregation.type === 'metric') {
    const metric = metrics.find((metric) => metric.metricId === aggregation.metricId);
    return metric ? aggregation : getDefaultAggregation(fields, metrics, relationName);
  }

  const defaultTargetField = first(getFieldsForAggregation(aggregation, fields));
  if (defaultTargetField === undefined) {
    return getDefaultAggregation(fields, metrics, relationName);
  }

  if (!('key' in aggregation)) {
    return {
      ...aggregation,
      key: defaultTargetField.key,
    };
  }

  const field = fields.find((f) => f.key === aggregation.key);
  if (!field) {
    return {
      ...aggregation,
      key: defaultTargetField.key,
    };
  }

  return aggregation;
};

export const ensureValidAggregations = (
  aggregations: Aggregation[],
  fields: Field[],
  metrics: Metric[],
): Aggregation[] => {
  return aggregations.map((aggregation) => ensureValidAggregation(aggregation, fields, metrics));
};

export const changeAggregationType = (
  aggregation: Aggregation,
  newType: AggregationExtendedType,
  fields: Field[],
): Aggregation => {
  if (newType === 'custom') {
    return {
      type: newType,
      value: { expression: '', version: '1' },
      property: aggregation.property,
    };
  }

  if (newType === 'count') {
    return {
      type: newType,
      property: aggregation.property,
    };
  }

  const validFields = getFieldsForAggregationType(newType, fields);
  const isCurrentFieldValid = validFields.some((f) => f.key === aggregation.key);
  const key = isCurrentFieldValid ? aggregation.key : first(validFields)?.key;

  if (key === undefined) {
    return aggregation;
  }

  const defaultSortField = fields.find(({ type }) => type === 'Date') ?? first(fields);
  const existingSort = 'sort' in aggregation ? aggregation.sort : undefined;
  const sort: common.Sorting[] | undefined =
    newType === 'first' || newType === 'last'
      ? (existingSort ?? [
          {
            key: defaultSortField?.key ?? '',
            direction: 'ASC',
          },
        ])
      : undefined;

  return {
    type: aggregationExtendedTypeToAggregationType(newType),
    key,
    property: aggregation.property,
    ...(sort !== undefined ? { sort } : {}),
  };
};

export const buildMetricAggregation = (metric: Metric): Aggregation => {
  return {
    type: 'metric',
    property: { key: nameToKey(metric.name), name: metric.name },
    metricId: metric.metricId,
  };
};
