import { Fragment, useEffect, useMemo, useRef, useState, Suspense, lazy } from 'react';
import { isEqual } from 'lodash';

import { AnalyticsContextProvider, useTrackEvent } from '@/lib/analytics';
import { Breakpoint, useScreenSize } from '@/lib/hooks/use-screen-size';
import { useTitle } from '@/lib/hooks/use-title';
import { useAccountContext } from '@/lib/accounts/context';
import { useKonstaabel } from '@/lib/hooks/use-konstaabel';
import { ButtonGroup } from '@/components/button';

import { useExplorationContext } from './exploration-context';
import { useDirtyContext } from '../dirty-context';
import { Icon } from '../../components/icon';
import { ScrollToSelected } from './scroll-to-selected';
import { ExplorationSearchModal } from '../exploration-search';
import { ExplorationCell } from './exploration-cell';
import { AddSection } from './add-section';
import {
  getAbsoluteCellIndex,
  getExplorationIconName,
  getExplorationType,
  getVariableDefinitions,
  isModelDetailExploration,
  isPersistedModelDetailExploration,
} from '../utils';
import { Sidebar } from './sidebar';
import { CellDropZone } from './exploration-layout/cell-dropzone';
import { getCellRows, measureCells } from './exploration-layout/utils';

const InfiniteCanvas = lazy(() => import('../components/canvas'));

import { CanvasOptions } from '../components/canvas/types';

import { getCellByIndex } from './utils';

import styles from './exploration.module.scss';
import canvasStyles from '../components/canvas/canvas.module.scss';
import layoutStyles from './exploration-layout/exploration-layout.module.scss';
interface ExplorationViewProps {
  accountId: string;
}

export const ExplorationView = (props: ExplorationViewProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const scrollToRef = useRef<HTMLDivElement | null>(null);
  const selectedCellRef = useRef<HTMLDivElement | null>(null);
  const cellsRef = useRef<HTMLDivElement | null>(null);

  const [explorationListIndex, setExplorationListIndex] = useState<number | null>(null);
  const [explorationRowHeights, setExplorationRowHeights] = useState<(number | undefined)[]>([]);

  const { confirmUnsavedChagesIfNeeded } = useDirtyContext();

  useKonstaabel();

  const {
    exploration,
    parameters,
    selectedCellIndex,
    selectedCell,
    isEditorOpen,
    setExploration,
    resetExploration,
    scrollToIndex,
    closeEditor,
    addCells,
    openEditor,
    scrollToCell,
    isDirty,
    cellCount,
    isCanvasView,
    setCanvasView,
    selectCellById,
    deselectCell,
  } = useExplorationContext();

  const setRowHeightOverride = (index: number, height: number | undefined) => {
    setExplorationRowHeights((prev) => {
      const next = [...prev];
      next[index] = height;
      return next;
    });
  };

  const trackEvent = useTrackEvent();
  const screenSize = useScreenSize();
  const accountContext = useAccountContext();
  const canvasFeatureEnabled = accountContext.enabledFeatures.includes('canvas');

  useTitle(exploration.name);

  useEffect(() => {
    trackEvent('Exploration Opened', {
      explorationId: exploration.explorationId,
      explorationSourceId: exploration.options?.explorationSourceId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exploration.options?.explorationSourceId]);

  const scrollIntoView = (elem?: HTMLElement | null) =>
    elem?.scrollIntoView({ behavior: 'smooth' });

  useEffect(() => {
    if (scrollToIndex !== null && scrollToRef.current !== null) {
      // make sure to scroll to the top for the first cell
      scrollIntoView(scrollToIndex === 0 ? containerRef.current : scrollToRef.current);
      scrollToCell(null);
    }
  }, [scrollToCell, scrollToIndex]);

  const addSection = (index = cellCount) => {
    confirmUnsavedChagesIfNeeded({
      onConfirm: () => {
        closeEditor();
        setExplorationListIndex(index);
      },
    });
  };

  const renderExplorationList = (index: number) => (
    <AnalyticsContextProvider
      properties={{
        intent: 'new block',
        exploration,
        atIndex: index,
      }}>
      <ExplorationSearchModal
        exploration={exploration}
        onClickExploration={(selectedExploration) => {
          addCells(selectedExploration.view.cells, index);
          openEditor({ cellIndex: index });
          scrollToCell(index);
          setExplorationListIndex(null);
          trackEvent('Exploration Cells Added', {
            explorationId: exploration.explorationId,
            selectedExploration: selectedExploration.explorationId,
            selectedName: selectedExploration.name,
            atIndex: index,
          });
        }}
        onClose={() => setExplorationListIndex(null)}
        selectIndividualCells
      />
    </AnalyticsContextProvider>
  );

  const explorationType = getExplorationType(exploration);
  const explorationIconName = getExplorationIconName(exploration);

  const isDesktopView = screenSize.breakpoint > Breakpoint.md;
  const isDetailExploration = isModelDetailExploration(exploration);
  const isPersisted = isPersistedModelDetailExploration(exploration);

  const rows = useMemo(() => getCellRows(exploration.view.cells), [exploration.view.cells]);

  useEffect(() => {
    setCanvasView(false);
  }, [setCanvasView, exploration.options?.explorationSourceId]);

  const persistCanvas = (state: CanvasOptions) => {
    if (!isEqual(state, exploration.view.canvas)) {
      setExploration({ ...exploration, view: { ...exploration.view, canvas: state } });
    }
  };

  const handleViewChange = (value: string) => {
    if (value === 'canvas' && exploration.view.canvas === undefined) {
      const nodes = cellsRef.current !== null ? measureCells(cellsRef.current) : [];
      persistCanvas({ nodes, edges: [] });
    }

    setCanvasView(value === 'canvas');
  };

  return (
    <div className={styles.explorationContent} ref={containerRef}>
      <div className={styles.splitView}>
        <div className={styles.main}>
          <ScrollToSelected
            direction="up"
            cellIndex={selectedCellIndex}
            cellRef={selectedCellRef}
            onClick={() => scrollToCell(selectedCellIndex)}
          />

          <header className={styles.header}>
            {isDetailExploration ? (
              <>
                {isDirty ? (
                  <div className={styles.dirtyHeader}>
                    {isPersisted ? (
                      <h1>
                        Exploring from
                        <button
                          type="button"
                          className={styles.resetButton}
                          title="Reset exploration"
                          onClick={() => resetExploration(parameters)}>
                          <Icon name="Exploration" size={32} />
                          <span className="truncate">{exploration.name}</span>
                        </button>
                      </h1>
                    ) : (
                      <div className={styles.header}>
                        <Icon name="Exploration" size={32} />
                        <h1>{exploration.name}</h1>
                        <div className={styles.templateTag}>Template</div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={styles.header}>
                    <Icon name="Exploration" size={32} />
                    <h1>{exploration.name}</h1>
                    <div className={styles.templateTag}>Template</div>
                  </div>
                )}

                {canvasFeatureEnabled && (
                  <ButtonGroup
                    value={isCanvasView ? 'canvas' : 'rows'}
                    options={[
                      { value: 'rows', label: 'Notebook' },
                      { value: 'canvas', label: 'Canvas' },
                    ]}
                    onChange={handleViewChange}
                  />
                )}
              </>
            ) : (
              <>
                {isDirty ? (
                  <div className={styles.dirtyHeader}>
                    {explorationType === null ? (
                      <h1>Explore</h1>
                    ) : (
                      <h1>
                        {explorationType === 'ai' ? (
                          <Icon name={explorationIconName} size={24} />
                        ) : (
                          <span className={styles.headerLabel}>Exploring from</span>
                        )}
                        <button
                          type="button"
                          className={styles.resetButton}
                          title="Reset exploration"
                          onClick={() => resetExploration(parameters)}>
                          {explorationType !== 'ai' && (
                            <Icon name={explorationIconName} size={24} />
                          )}
                          <span className="truncate">{exploration.name}</span>
                        </button>
                      </h1>
                    )}
                  </div>
                ) : (
                  <div className={styles.header}>
                    <Icon name={explorationIconName} size={32} />
                    <h1>{exploration.name}</h1>
                  </div>
                )}

                {canvasFeatureEnabled && (
                  <ButtonGroup
                    value={isCanvasView ? 'canvas' : 'rows'}
                    options={[
                      { value: 'rows', label: 'Notebook' },
                      { value: 'canvas', label: 'Canvas' },
                    ]}
                    onChange={handleViewChange}
                  />
                )}
              </>
            )}
          </header>

          {isCanvasView ? (
            <Suspense fallback={<div className={canvasStyles.placeholder} />}>
              <InfiniteCanvas
                accountId={props.accountId}
                selectedCell={selectedCell}
                scrollToCellId={
                  scrollToIndex !== null
                    ? (getCellByIndex(scrollToIndex, exploration)?.id ?? null)
                    : null
                }
                exploration={exploration}
                parameters={parameters}
                isDesktopView={isDesktopView}
                onChange={persistCanvas}
                onSelectionChange={(id) => {
                  if (id !== undefined) {
                    selectCellById(id);
                  } else {
                    deselectCell();
                  }
                }}
              />

              <div className={canvasStyles.canvasCta}>
                <AddSection compact={false} onClick={() => addSection(0)} />
              </div>
              {explorationListIndex === 0 && renderExplorationList(0)}
            </Suspense>
          ) : (
            <div ref={cellsRef}>
              {isEditorOpen && <div className={styles.overlay} onClick={closeEditor} />}
              <CellDropZone index={0} addBefore mergeIntoRow={false}>
                <AddSection onClick={() => addSection(0)} compact={false} />
              </CellDropZone>
              {explorationListIndex === 0 && renderExplorationList(0)}
              {rows.map((cells, rowIndex) => {
                const nextRowCellIndex = getAbsoluteCellIndex(rows, rowIndex + 1, 0);
                const isLastRow = rowIndex === rows.length - 1;
                return (
                  <Fragment key={rowIndex}>
                    <div className={layoutStyles.explorationRow}>
                      {cells.map((cell, i) => {
                        const cellIndex = getAbsoluteCellIndex(rows, rowIndex, i);
                        const isSelected = cellIndex === selectedCellIndex;
                        return (
                          <Fragment key={cell.id}>
                            <ExplorationCell
                              index={cellIndex}
                              accountId={props.accountId}
                              exploration={exploration}
                              parameters={parameters}
                              cell={cell}
                              selected={isSelected}
                              height={cell.viewOptions?.height}
                              rowHeightOverride={explorationRowHeights[rowIndex]}
                              setRowHeightOverride={(height) =>
                                setRowHeightOverride(rowIndex, height)
                              }
                              ref={(element) => {
                                if (cellIndex === scrollToIndex) {
                                  scrollToRef.current = element;
                                }
                                if (isSelected) {
                                  selectedCellRef.current = element;
                                }
                              }}
                              variables={getVariableDefinitions(exploration)}
                              isCollapsible={cells.length === 1 || !isDesktopView}
                              onClick={() =>
                                !isSelected && isDesktopView && openEditor({ cellIndex })
                              }
                            />
                            {i < cells.length - 1 && (
                              <CellDropZone
                                index={cellIndex}
                                vertical
                                mergeIntoRow
                                className={layoutStyles.cellSeparator}
                              />
                            )}
                          </Fragment>
                        );
                      })}
                    </div>
                    <CellDropZone index={nextRowCellIndex - 1} mergeIntoRow={false}>
                      <AddSection
                        onClick={() => addSection(nextRowCellIndex)}
                        compact={!isLastRow}
                      />
                    </CellDropZone>
                    {explorationListIndex === nextRowCellIndex &&
                      renderExplorationList(nextRowCellIndex)}
                  </Fragment>
                );
              })}
              <ScrollToSelected
                direction="down"
                cellIndex={selectedCellIndex}
                cellRef={selectedCellRef}
                onClick={() => scrollToCell(selectedCellIndex)}
              />
            </div>
          )}
        </div>
        <Sidebar onAddSection={() => addSection()} />
      </div>
    </div>
  );
};
