import { compact, first, nth, pick, omit, omitBy, isEmpty } from 'lodash';

import { getDefaultSorting } from '@/explore/utils/slicing';
import { ensureUniqueFieldKey } from '@/explore/edit-pipeline/utils';

import { sortingToGql, QuerySortDirection } from '../../../graphql';
import {
  AggregatedVisualisation,
  DereferencedPipeline,
  Exploration,
  Field,
  GroupAggregateOperation,
  Grouping,
  Metric,
  Model,
  Pipeline,
  Visualisation,
  VisualisationViewOptions,
  isAggregatedVisualisation,
  BigNumberWithComparisonOptions,
  Aggregation,
  ChartType,
  SeriesViewOptions,
} from '../../types';
import { ensureValidAggregations, getAggregationLabel } from '../../utils/aggregation';
import { getExplorationVariables } from '../../utils';
import { getDereferencedPipelineFields, PipelineStateContext } from '../../pipeline/state';
import { dereferencePipeline } from '../../pipeline/utils';
import {
  ensureValidGroupings,
  fillLastDateGroup,
  sortDateGroupingsLast,
} from '../../utils/grouping';
import {
  getLastGroupAggregate,
  getLastGroupAggregateIndex,
  getLastSwitchToRelationIndex,
} from '../../pipeline/operation';

type VisualisationQuery = {
  pipeline: DereferencedPipeline;
  sort?: { key: string; direction: QuerySortDirection }[];
  limit?: number;
  groups: Grouping[];
};

// Based on the initial pipeline and visualisation options, return pipeline that returns data suitable
// for visualisation.
export const getQueryForVisualisation = (
  pipeline: DereferencedPipeline,
  fields: Field[],
  visualisation: Visualisation,
): VisualisationQuery => {
  const { operations } = pipeline;

  if (isAggregatedVisualisation(visualisation)) {
    const groups = fillLastDateGroup(
      sortDateGroupingsLast(visualisation.aggregation.groups),
      fields,
    );
    const groupField = fields.find((field) => field.key === groups.at(-1)?.key);
    return {
      pipeline: {
        ...pipeline,
        operations: [
          ...operations,
          {
            operation: 'groupAggregate',
            parameters: {
              aggregations: visualisation.aggregation.aggregations,
              groups,
            },
          },
        ],
      },
      groups,
      ...(groupField?.type !== 'Date' ? { limit: 25 } : { limit: 500 }),
      ...(visualisation.mainAxisKey !== undefined
        ? { sort: [{ key: visualisation.mainAxisKey, direction: QuerySortDirection.Desc }] }
        : { sort: sortingToGql(visualisation.viewOptions?.bigNumber?.sort ?? []) }),
    };
  }

  return {
    pipeline,
    groups: [],
    ...(visualisation.mainAxisKey !== undefined
      ? { sort: [{ key: visualisation.mainAxisKey, direction: QuerySortDirection.Desc }] }
      : { sort: sortingToGql(visualisation.viewOptions?.bigNumber?.sort ?? []) }),
  };
};

export const getQueryForHistogram = (
  pipeline: DereferencedPipeline,
  visualisation: Visualisation,
): VisualisationQuery => {
  return {
    pipeline: {
      ...pipeline,
      operations: [
        ...pipeline.operations,
        {
          operation: 'histogram',
          parameters: {
            key: visualisation.mainAxisKey ?? '',
            numBuckets: 10,
          },
        },
      ],
    },
    groups: [],
  };
};

export const getVisualisationGroupByField = (field: Field): Grouping => {
  if (field.type === 'Date') {
    return {
      key: field.key,
      fill: true,
      precision: 'month',
    };
  }

  return {
    key: field.key,
  };
};

export const generateVisualisationFromGroupAggregate = (
  groupAggregate: GroupAggregateOperation,
  fields: Field[],
): Visualisation => {
  const { groups } = groupAggregate.parameters;
  const aggregations = groupAggregate.parameters.aggregations;

  const bigNumberFieldKey =
    groups.length === 0 ? first(getBigNumberFields(fields))?.key : undefined;

  const firstAggregation = first(aggregations);
  const viewOptions = {
    axes: {
      right: {
        keys: [],
      },
    },
    ...(bigNumberFieldKey !== undefined
      ? {
          bigNumber: { key: bigNumberFieldKey },
        }
      : {}),
  };

  if (groups.length > 1 && firstAggregation !== undefined) {
    const aggregations = [
      {
        type: 'sum' as const,
        key: firstAggregation.property.key,
        property: {
          key: 'sum',
          name: firstAggregation.property.name,
        },
      },
    ];
    return {
      aggregation: {
        aggregations,
        groups,
      },
      ...visualisationKeysFromAggregation({ aggregations, groups }),
      viewOptions,
    };
  }

  return {
    mainAxisKey: first(groups)?.key,
    valueKeys: aggregations.map((aggr) => aggr.property.key),
    viewOptions,
  };
};

export const generateVisualisationFromFields = (fields: Field[]): AggregatedVisualisation => {
  const field = fields.find((field) => field.type === 'Date') ?? fields[0];
  const aggregation = {
    aggregations: [
      {
        type: 'count' as const,
        property: {
          key: 'count',
          name: 'Count',
        },
      },
    ],
    groups: [
      isDateField(field)
        ? {
            key: field.key,
            fill: true,
            precision: 'month' as const,
          }
        : {
            key: field.key,
          },
    ],
  };

  return {
    aggregation,
    ...visualisationKeysFromAggregation(aggregation),
    viewOptions: {
      axes: {
        right: {
          keys: [],
        },
      },
    },
  };
};

export const generateVisualisation = (
  pipeline: DereferencedPipeline,
  ctx: PipelineStateContext,
): Visualisation => {
  const fields = getDereferencedPipelineFields(pipeline, ctx);
  const lastGroupAggregate = getLastGroupAggregate(pipeline.operations);
  const shouldUseGroupAggregate =
    lastGroupAggregate !== undefined &&
    (lastGroupAggregate.parameters.groups.length > 0 ||
      lastGroupAggregate.parameters.aggregations.length > 0) &&
    getLastGroupAggregateIndex(pipeline.operations) >
      getLastSwitchToRelationIndex(pipeline.operations);

  return shouldUseGroupAggregate
    ? generateVisualisationFromGroupAggregate(lastGroupAggregate, fields)
    : generateVisualisationFromFields(fields);
};

export const isBigNumberToggleable = (visualisation: Visualisation, fields: Field[]) => {
  if (isAggregatedVisualisation(visualisation) && visualisation.aggregation.groups.length === 1) {
    const field = fields.find((field) => field.key === visualisation.aggregation.groups[0].key);
    return field !== undefined && isDateField(field);
  }
  return fields.find((field) => field.key === visualisation.mainAxisKey)?.type === 'Date';
};

export const getBigNumberFields = (fields: Field[]) => fields.filter(isNumericField);

export const getBigNumberComparisonDefaults = (key: string) => ({
  comparisonKey: key,
  comparisonDirection: 'higher',
  comparisonType: 'absolute',
  showComparisonLabel: false,
});

const getBigNumberSortingDefaults = (fields: Field[]) => ({
  sort: [getDefaultSorting(fields)],
});

const ensureValidBigNumberFieldKey = (
  bigNumberFieldKey: string | undefined,
  ctx: {
    fields: Field[];
    mainAxisKey?: string;
    aggregation?: AggregatedVisualisation['aggregation'];
  },
) => {
  const fields = getBigNumberFields(ctx.fields);

  if (ctx.mainAxisKey === undefined) {
    if (fields.length === 0) {
      throw new Error('No valid big number fields found');
    }
    return (
      fields.find((field) => field.key === bigNumberFieldKey)?.key ??
      (fields.find(isNumericField) ?? fields[0])?.key
    );
  }

  if (bigNumberFieldKey === undefined) {
    return bigNumberFieldKey;
  }

  if (ctx.aggregation !== undefined && ctx.aggregation.groups.length > 1) {
    return undefined;
  }

  if (
    ctx.mainAxisKey !== undefined &&
    ctx.fields.find((field) => field.key === ctx.mainAxisKey)?.type !== 'Date'
  ) {
    return undefined;
  }

  if (fields.length === 0) {
    throw new Error('No valid big number fields found');
  }
  const isValidField = fields.some((field) => field.key === bigNumberFieldKey);
  return isValidField ? bigNumberFieldKey : first(fields)?.key;
};

const ensureValidAxes = (
  axes: VisualisationViewOptions['axes'],
  ctx: {
    valueKeys?: string[];
    chartType: string;
  },
) => {
  if (ctx.chartType === 'grouped-timeseries') {
    return {
      ...axes,
      right: {
        keys: axes?.right?.keys.filter((key) => ctx.valueKeys?.includes(key) ?? false) ?? [],
      },
    };
  }
  return undefined;
};

export const ensureValidViewOptions = (
  viewOptions: VisualisationViewOptions | undefined,
  ctx: {
    fields: Field[];
    mainAxisKey?: string;
    aggregation?: AggregatedVisualisation['aggregation'];
    valueKeys?: string[];
    chartType: string;
  },
) => {
  const newOptions: VisualisationViewOptions = { ...viewOptions };

  if (ctx.chartType === 'grouped-timeseries') {
    newOptions.axes = ensureValidAxes(viewOptions?.axes, ctx);
  } else {
    delete newOptions.axes;
  }

  const bigNumberKey = ensureValidBigNumberFieldKey(
    newOptions?.bigNumber?.key ?? newOptions?.bigNumberFieldKey,
    ctx,
  );

  // Clean-up of a deprecated field
  delete newOptions.bigNumberFieldKey;

  if (bigNumberKey === undefined) {
    return omit(newOptions, 'bigNumber');
  }

  newOptions.bigNumber = {
    ...newOptions.bigNumber,
    key: bigNumberKey,
  };

  const comparisonKey = isBigNumberWithComparison(newOptions?.bigNumber)
    ? newOptions.bigNumber.comparisonKey
    : undefined;

  if (comparisonKey !== undefined) {
    newOptions.bigNumber = {
      ...getBigNumberComparisonDefaults(bigNumberKey),
      ...newOptions.bigNumber,
    };
  }

  if (ctx.mainAxisKey === undefined) {
    newOptions.bigNumber = {
      ...getBigNumberSortingDefaults(ctx.fields),
      ...newOptions.bigNumber,
    };
  }

  return setBigNumberOptions(newOptions, undefined, ctx);
};

export const ensureValidVisualisation = (
  visualisation: Visualisation,
  ctx: {
    pipeline: Pipeline;
    exploration: Exploration;
    models: Model[];
    metrics: Metric[];
  },
) => {
  if (isAggregatedVisualisation(visualisation)) {
    return ensureValidAggregatedVisualisation(visualisation, ctx);
  }

  const dereferencedPipeline = dereferencePipeline(ctx.pipeline, ctx.exploration);
  const variables = getExplorationVariables(ctx.exploration);
  const fields = getDereferencedPipelineFields(dereferencedPipeline, { ...ctx, variables });

  let mainAxisKey = fields.find((field) => field.key === visualisation.mainAxisKey)?.key;

  if (visualisation.mainAxisKey !== undefined && mainAxisKey === undefined) {
    mainAxisKey =
      fields.find(isDateField)?.key ?? fields.find(isNumericField)?.key ?? first(fields)?.key;
  }

  const defaultValueKeys = fields.filter(isNumericField).map((f) => f.key);
  const validValueKeys = visualisation.valueKeys.filter((key) =>
    fields.some((field) => field.key === key),
  );

  let valueKeys: string[] = [];
  if (mainAxisKey !== undefined) {
    valueKeys = validValueKeys;
  }
  if (valueKeys.length === 0 && visualisation.mainAxisKey !== undefined) {
    if (defaultValueKeys.length === 0) {
      throw new Error('No valid value keys found');
    }
    valueKeys = defaultValueKeys;
  }

  const validVisualisation = {
    mainAxisKey,
    valueKeys,
  };

  const { pipeline: visualisationPipeline } = getQueryForVisualisation(
    dereferencedPipeline,
    fields,
    validVisualisation,
  );

  const visualisationFields = getDereferencedPipelineFields(visualisationPipeline, {
    ...ctx,
    variables,
  });

  const chartType = getChartType(visualisationFields, validVisualisation);

  return {
    ...validVisualisation,
    viewOptions: ensureValidViewOptions(visualisation.viewOptions, {
      fields: visualisationFields,
      mainAxisKey: validVisualisation.mainAxisKey,
      valueKeys: validVisualisation.valueKeys,
      chartType,
    }),
  };
};

export const ensureValidAggregatedVisualisation = (
  visualisation: AggregatedVisualisation,
  ctx: {
    pipeline: Pipeline;
    exploration: Exploration;
    models: Model[];
    metrics: Metric[];
  },
) => {
  const dereferencedPipeline = dereferencePipeline(ctx.pipeline, ctx.exploration);
  const variables = getExplorationVariables(ctx.exploration);
  const fields = getDereferencedPipelineFields(dereferencedPipeline, { ...ctx, variables });

  const validAggregation = {
    aggregations: ensureValidAggregations(
      visualisation.aggregation.aggregations,
      fields,
      ctx.metrics,
    ),
    groups: ensureValidGroupings(visualisation.aggregation.groups, fields),
  };

  const xys = visualisationKeysFromAggregation(validAggregation);

  const validVisualisation = {
    aggregation: validAggregation,
    ...xys,
  };

  const { pipeline: visualisationPipeline } = getQueryForVisualisation(
    dereferencedPipeline,
    fields,
    validVisualisation,
  );

  const visualisationFields = getDereferencedPipelineFields(visualisationPipeline, {
    ...ctx,
    variables,
  });

  const chartType = getChartType(visualisationFields, validVisualisation);

  return {
    ...validVisualisation,
    viewOptions: ensureValidViewOptions(visualisation.viewOptions, {
      fields: visualisationFields,
      mainAxisKey: validVisualisation.mainAxisKey,
      aggregation: validVisualisation.aggregation,
      valueKeys: validVisualisation.valueKeys,
      chartType,
    }),
  };
};

export const isBigNumberWithComparison = (
  bigNumber?: VisualisationViewOptions['bigNumber'],
): bigNumber is BigNumberWithComparisonOptions => {
  return bigNumber !== undefined && 'comparisonKey' in bigNumber;
};

export const isBigNumberWithSorting = (mainAxisKey?: string) => {
  return mainAxisKey === undefined;
};

export const setBigNumberOptions = (
  newOptions: VisualisationViewOptions,
  viewOptions: VisualisationViewOptions | undefined,
  ctx: {
    mainAxisKey?: string;
  },
): VisualisationViewOptions => {
  const options = {
    ...viewOptions,
    ...newOptions,
  };

  const bigNumberKey = newOptions.bigNumber?.key;

  if (bigNumberKey === undefined) {
    return omit(options, 'bigNumber');
  }

  options.bigNumber = {
    key: bigNumberKey,
    ...(isBigNumberWithComparison(options.bigNumber)
      ? pick(options.bigNumber, [
          'comparisonKey',
          'comparisonDirection',
          'comparisonType',
          'showComparisonLabel',
        ])
      : {}),
    ...(isBigNumberWithSorting(ctx.mainAxisKey) ? pick(options.bigNumber, ['sort']) : {}),
  };

  return options;
};

export const toggleVisualisationAggregation = (
  aggregated: boolean,
  visualisation: Visualisation,
  fields: Field[],
) => {
  if (!aggregated && isAggregatedVisualisation(visualisation)) {
    const valueKeys = visualisation.aggregation.aggregations.reduce(
      (acc, aggregation) => (aggregation.key !== undefined ? [...acc, aggregation.key] : acc),
      [] as string[],
    );
    return {
      mainAxisKey: visualisation.mainAxisKey,
      valueKeys:
        valueKeys.length > 0 ? valueKeys : [fields.find(isNumericField)?.key ?? fields[0].key],
      viewOptions: visualisation.viewOptions,
    };
  }
  if (aggregated && !isAggregatedVisualisation(visualisation)) {
    const newMainAxisField =
      fields.find((field) => field.key === visualisation.mainAxisKey) ??
      fields.find(isDateField) ??
      first(fields);
    if (newMainAxisField === undefined) {
      throw new Error(`Field with key ${visualisation.mainAxisKey} not found`);
    }
    const aggregation = {
      aggregations: visualisation.valueKeys.map((valueKey) => {
        const field = fields.find((field) => field.key === valueKey);
        if (field === undefined) {
          throw new Error(`Field with key ${valueKey} not found`);
        }
        return {
          type: 'sum' as const,
          key: field.key,
          property: { key: `sum_${field.key}`, name: getAggregationLabel('sum', field) },
        };
      }),
      groups: [
        isDateField(newMainAxisField)
          ? { key: newMainAxisField.key, precision: 'month' as const, fill: true }
          : { key: newMainAxisField.key },
      ],
    };
    return {
      aggregation,
      ...visualisationKeysFromAggregation(aggregation),
      viewOptions: visualisation.viewOptions,
    };
  }
  return visualisation;
};

export const getChartType = (fields: Field[], visualisation: Visualisation) => {
  const mainAxisField = fields.find((field) => field.key === visualisation.mainAxisKey);
  if (
    mainAxisField !== undefined &&
    isNumericField(mainAxisField) &&
    isAggregatedVisualisation(visualisation) &&
    visualisation.aggregation.groups.length === 1 &&
    visualisation.aggregation.aggregations.length === 1 &&
    first(visualisation.aggregation.aggregations)?.type === 'count'
  ) {
    return 'histogram';
  }
  if (
    mainAxisField !== undefined &&
    isDateField(mainAxisField) &&
    (!isAggregatedVisualisation(visualisation) ||
      sortDateGroupingsLast(visualisation.aggregation.groups).at(-1)?.precision !== 'day_of_week')
  ) {
    return 'grouped-timeseries';
  }
  if (mainAxisField !== undefined) {
    return 'bar';
  }
  return 'single-value';
};

export const setVisualisationAggregations = (
  visualisation: Visualisation,
  aggregations: Aggregation[],
  fields: Field[],
) => {
  if (!isAggregatedVisualisation(visualisation)) {
    throw new Error('Visualisation is not aggregated');
  }
  const aggregation = {
    ...visualisation.aggregation,
    aggregations: aggregations.map((agg) => ({
      ...agg,
      property: {
        ...agg.property,
        key: ensureUniqueFieldKey(fields, agg.property.key),
      },
    })),
  };
  const keys = visualisationKeysFromAggregation(aggregation);
  return {
    ...visualisation,
    aggregation,
    ...keys,
    viewOptions: updateSecondaryAxisKeys(
      visualisation.viewOptions,
      visualisation.valueKeys,
      keys.valueKeys,
    ),
  };
};

export const setVisualisationValueKeys = (visualisation: Visualisation, valueKeys: string[]) => {
  return {
    ...visualisation,
    valueKeys,
    viewOptions: updateSecondaryAxisKeys(
      visualisation.viewOptions,
      visualisation.valueKeys,
      valueKeys,
    ),
  };
};

export const setSecondaryAxisKey = (
  key: string,
  useSecondaryAxis: boolean,
  viewOptions: Visualisation['viewOptions'],
) => {
  const existingKeys = viewOptions?.axes?.right?.keys ?? [];
  return {
    ...viewOptions,
    axes: {
      ...viewOptions?.axes,
      right: {
        ...viewOptions?.axes?.right,
        keys: useSecondaryAxis ? [...existingKeys, key] : existingKeys.filter((k) => k !== key),
      },
    },
  };
};

const setSeriesMeta = (
  seriesViewOptions: SeriesViewOptions,
  viewOptions: Visualisation['viewOptions'],
) => {
  return {
    ...viewOptions,
    series: omitBy(seriesViewOptions, isEmpty), // Remove empty values
  };
};

export const setSeriesChartType = (
  key: string,
  chartType: ChartType,
  viewOptions: Visualisation['viewOptions'],
): Visualisation['viewOptions'] => {
  return setSeriesMeta(
    {
      ...viewOptions?.series,
      [key]: {
        ...viewOptions?.series?.[key],
        chartType,
      },
    },
    viewOptions,
  );
};

export const removeSeriesChartType = (
  key: string,
  viewOptions: Visualisation['viewOptions'],
): Visualisation['viewOptions'] => {
  return setSeriesMeta(
    {
      ...viewOptions?.series,
      [key]: omit(viewOptions?.series?.[key], 'chartType'),
    },
    viewOptions,
  );
};

export const setSeriesColor = (
  key: string,
  color: string,
  viewOptions: Visualisation['viewOptions'],
): Visualisation['viewOptions'] => {
  return setSeriesMeta(
    {
      ...viewOptions?.series,
      [key]: {
        ...viewOptions?.series?.[key],
        color,
      },
    },
    viewOptions,
  );
};

export const enableShowValues = (
  key: string,
  viewOptions: Visualisation['viewOptions'],
): Visualisation['viewOptions'] => {
  return setSeriesMeta(
    {
      ...viewOptions?.series,
      [key]: {
        ...viewOptions?.series?.[key],
        showValues: true,
      },
    },
    viewOptions,
  );
};

export const disableShowValues = (
  key: string,
  viewOptions: Visualisation['viewOptions'],
): Visualisation['viewOptions'] => {
  return setSeriesMeta(
    {
      ...viewOptions?.series,
      [key]: omit(viewOptions?.series?.[key], 'showValues'),
    },
    viewOptions,
  );
};

export const isDateField = (field: Field) => field.type === 'Date';

export const isNumericField = (field: Field) =>
  field.type === 'Number' || field.type === 'Float' || field.type === 'Integer';

export const visualisationKeysFromAggregation = (
  aggregation: AggregatedVisualisation['aggregation'],
) => ({
  valueKeys: aggregation.aggregations.map((aggregation) => aggregation.property.key),
  mainAxisKey: sortDateGroupingsLast(aggregation.groups).at(-1)?.key,
});

const updateSecondaryAxisKeys = (
  viewOptions: Visualisation['viewOptions'],
  valueKeys: string[],
  newValueKeys: string[],
) => {
  if (viewOptions === undefined || viewOptions.axes === undefined) {
    return viewOptions; // Nothing to update
  }
  return {
    ...viewOptions,
    axes: {
      ...viewOptions.axes,
      right: {
        keys: compact(
          viewOptions.axes.right?.keys.map((key) => {
            return nth(newValueKeys, valueKeys.indexOf(key));
          }),
        ),
      },
    },
  };
};
