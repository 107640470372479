import React, { useRef, useState } from 'react';
import { isArray, last, uniq } from 'lodash';
import { ParentSize } from '@visx/responsive';
import { AxisBottom } from '@visx/axis';
import { localPoint } from '@visx/event';
import classNames from 'classnames';
import { toZonedTime } from 'date-fns-tz';

import { findClosestDate } from '@/lib/date';
import { CheckboxWithLabel } from '@/components/form/checkbox';
import { Grouping, TimeAggregationPeriod } from '@/explore/types';

import { ColoredLegend } from '../legend';
import { DefaultChartHeight } from '../common';
import { getTickFormatFn, getTickValues, getValueScale } from './utils';
import { getSeries, getValueKeys } from '../grouped-chart/utils';
import { GroupedChartData, TimeSeriesData } from '../grouped-chart/types';
import { GroupedChart } from '../grouped-chart';
import { scaleTimeWithPadding } from './scale-time-with-padding';
import { TimeSeriesChart } from './timeseries-chart';
import { StackedTimeSeriesChart } from './stacked-timeseries-chart';

import commonStyles from '../charts.module.scss';

const SeriesDepth = 2;
const WidthPerGroup = parseInt(commonStyles.widthPerGroup);
const YValuePaddingCoef = 0.1;
const HorizontalPadding = 20;
const VerticalPadding = 10;

export interface ChartColor {
  fill: string;
  highlight: string;
}

interface GroupedTimeSeriesChartProps {
  data: GroupedChartData<TimeSeriesData>;
  grouping: Grouping[];
  aggPeriod: TimeAggregationPeriod;
  stacked?: boolean;
  setStacked?: (stacked: boolean) => void;
  hideGrid?: boolean;
  timezone: string;
  isResized?: boolean;
  isTableVisible?: boolean;
}

const getDates = (data: GroupedChartData<TimeSeriesData>): Date[] =>
  isArray(data)
    ? data.flatMap((item) => getDates(item.items))
    : data.chartData.items.map((item) => item.dateValue);

const DefaultScalePadding = 0;
const MinBarChartScalePadding = 30;

export const GroupedTimeSeriesChart = (props: GroupedTimeSeriesChartProps) => {
  const {
    data,
    grouping,
    aggPeriod,
    stacked = false,
    setStacked,
    hideGrid = false,
    timezone,
    isResized = false,
    isTableVisible = true,
  } = props;

  const [highlightedDate, setHighlightedDate] = useState<Date | null>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const legend = getSeries(data).map(({ label, chartType, color }) => ({
    label,
    color,
    chartType,
  }));

  const isStackable =
    getValueKeys(data, 'left').length > 1 || getValueKeys(data, 'right').length > 1;

  const groupCount = grouping.length;
  const dates = uniq(getDates(data));
  const containsBarChartSeries = legend.some(({ chartType }) => chartType === 'bar');

  return (
    <ParentSize
      debounceTime={0}
      className={classNames(
        { [commonStyles.singleChart]: groupCount < 3 },
        { [commonStyles.customHeight]: !isTableVisible && isResized },
      )}>
      {(parent) => {
        const scalePadding = containsBarChartSeries
          ? Math.max(hideGrid ? 0 : MinBarChartScalePadding, parent.width / dates.length)
          : DefaultScalePadding;

        const dateScale = scaleTimeWithPadding(
          {
            domain: [
              toZonedTime(dates[0], timezone),
              toZonedTime(dates[dates.length - 1], timezone),
            ],
            range: [
              0,
              parent.width - (groupCount > 1 ? WidthPerGroup * (groupCount - SeriesDepth) : 0),
            ],
            zero: true,
          },
          scalePadding,
        );

        const verticalSpacing = (footerRef?.current?.clientHeight ?? 55) + (hideGrid ? 10 : 0);

        const chartHeight =
          groupCount > 2 || parent.height < verticalSpacing
            ? DefaultChartHeight
            : parent.height - verticalSpacing;

        const valueScales = {
          left: getValueScale(data, stacked, 'left', YValuePaddingCoef, [chartHeight, 0]),
          right: getValueScale(data, stacked, 'right', YValuePaddingCoef, [chartHeight, 0]),
        };

        const xTickSize = parent.width / dates.length;

        const handleMouseMove = (
          event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>,
        ) => {
          const { x } = localPoint(event) || { x: 0 };
          const hoveredDate = new Date(dateScale.invert(x - xTickSize / 2));
          const closestDate = findClosestDate(dates, hoveredDate) ?? last(dates);

          setHighlightedDate(new Date(closestDate));
        };

        return (
          <div>
            <GroupedChart
              width={parent.width}
              widthPerGroup={WidthPerGroup}
              data={data}
              renderChart={(chartData, chartWidth) => {
                const chartProps = {
                  data: chartData,
                  dateScale,
                  valueScales,
                  aggPeriod,
                  highlightedDate,
                  width: chartWidth,
                  height: chartHeight,
                  hideGrid,
                  onMouseMove: handleMouseMove,
                  onMouseLeave: () => setHighlightedDate(null),
                  timezone,
                };
                return stacked ? (
                  <StackedTimeSeriesChart {...chartProps} />
                ) : (
                  <TimeSeriesChart {...chartProps} />
                );
              }}
            />
            <div ref={footerRef}>
              {hideGrid ? null : (
                <svg width={parent.width} height={25}>
                  <AxisBottom
                    left={Math.max(0, WidthPerGroup * (groupCount - 2))}
                    top={0}
                    scale={dateScale}
                    hideZero
                    hideAxisLine
                    tickClassName={commonStyles.tickLabel}
                    tickLabelProps={() => ({
                      textAnchor: scalePadding > 0 ? 'middle' : 'start',
                      dy: -4,
                    })}
                    tickValues={getTickValues(aggPeriod, dateScale)}
                    tickFormat={getTickFormatFn(aggPeriod)}
                    rangePadding={300}
                  />
                </svg>
              )}

              <div
                style={{
                  marginLeft: Math.max(
                    HorizontalPadding,
                    WidthPerGroup * (grouping.length - SeriesDepth),
                  ),
                  marginRight: HorizontalPadding,
                  marginTop: hideGrid ? VerticalPadding : 0,
                }}>
                <div className={commonStyles.chartFooter}>
                  <ColoredLegend items={legend} />
                  {isStackable ? (
                    <CheckboxWithLabel
                      className={[commonStyles.stackingCheckbox]}
                      checked={stacked}
                      onChange={() => setStacked?.(!stacked)}>
                      Stacked
                    </CheckboxWithLabel>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </ParentSize>
  );
};
