import { isSameYear, parseISO } from 'date-fns';
import { isBoolean, isArray, isObject } from 'lodash';

import { formatDate, getIntervalFormat, getWeekdayName } from '@/lib/date';

import { TimeAggregationPeriod, ValueFormat } from '../types';

export interface PropertyFormatOptions {
  maxLength?: number;
  timezone?: string;
  precision?: TimeAggregationPeriod;
  format?: ValueFormat;
}

const defaultNumberFormat = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 6,
});

const eurFormat = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 6,
  currencyDisplay: 'narrowSymbol',
});

const usdFormat = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 6,
  currencyDisplay: 'narrowSymbol',
});

const gbpFormat = new Intl.NumberFormat(undefined, {
  style: 'currency',
  currency: 'GBP',
  maximumFractionDigits: 6,
  currencyDisplay: 'narrowSymbol',
});

const percentageFormat = new Intl.NumberFormat(undefined, {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const formatNumber = (value: number, format?: ValueFormat) => {
  switch (format) {
    case 'percentage':
      return percentageFormat.format(value);
    case 'eur':
      return eurFormat.format(value);
    case 'usd':
      return usdFormat.format(value);
    case 'gbp':
      return gbpFormat.format(value);
    default:
      return defaultNumberFormat.format(value);
  }
};

export function formatPropertyValue(
  value: any,
  type?: string,
  { maxLength, timezone, precision, format }: PropertyFormatOptions = {},
): string {
  if (value === null || value === '') {
    // TODO: better handle nully Model properties
    return '-';
  }

  if (type === 'Integer' && precision === 'day_of_week') {
    return getWeekdayName(value);
  }

  if (type === 'Date' && typeof value === 'string') {
    try {
      const d = parseISO(value);
      if (format === 'iso') {
        return value;
      }
      const shouldShowYear = !isSameYear(new Date(), d);
      let dateFormat = `MMMM do${shouldShowYear ? ' yyyy' : ''} HH:mm`;
      if (precision !== undefined) {
        dateFormat = getIntervalFormat(precision);
      }
      if (format === 'date') {
        dateFormat = getIntervalFormat('day');
      }
      if (format === 'time') {
        dateFormat = 'HH:mm';
      }
      return formatDate(d, dateFormat, timezone);
    } catch {
      return limitLength(String(value), maxLength);
    }
  }
  if (type === 'Boolean' || isBoolean(value)) {
    if (value === true || value === 'true') {
      return 'Yes';
    }
    if (value === false || value === 'false') {
      return 'No';
    }
  }
  if (isNumberType(type) && !isNaN(value)) {
    return formatNumber(Number(value), format);
  }
  if (type === 'Object') {
    if (isObject(value)) {
      return limitLength(JSON.stringify(value, null, 2), maxLength);
    }
    return value;
  }
  if (typeof value === 'string') {
    return limitLength(value, maxLength);
  }
  if (type === 'Array' || (type === '' && Array.isArray(value))) {
    if (isArray(value) && value.some((item) => isObject(item))) {
      return limitLength(JSON.stringify(value, null, 2), maxLength);
    }
    return limitLength(value.join(', '), maxLength);
  }
  if (
    type === 'Interval' ||
    (type === 'String' &&
      typeof value === 'object' &&
      Object.keys(value).every((key) =>
        ['days', 'hours', 'minutes', 'seconds', 'milliseconds'].includes(key),
      ))
  ) {
    // Handle date intervals returned by Postgres
    if (value['days'] !== undefined) {
      return `${value['days']} days`;
    }
    if (value['hours'] !== undefined) {
      return `${value['hours']} hours`;
    }
    if (value['minutes'] !== undefined) {
      return `${value['minutes']} minutes`;
    }
    if (value['seconds'] !== undefined) {
      return `${value['seconds']} seconds`;
    }
    if (value['milliseconds'] !== undefined) {
      return `${value['milliseconds']} milliseconds`;
    }
    if (Object.keys(value).length === 0) {
      return '-';
    }
  }
  return limitLength(String(value), maxLength);
}

// TODO: This belongs to model field logic
const isNumberType = (type: string | null | undefined) =>
  ['Number', 'Float', 'Integer'].includes(type ?? '');

export const limitLength = (value: string, maxLength: number | undefined) =>
  maxLength !== undefined && value.length > maxLength
    ? `${value.substring(0, maxLength - 3)}...`
    : value;
