import { createContext, useCallback, useContext } from 'react';
import { get } from 'lodash';

import { useTrackEvent } from '@/lib/analytics';
import { Json } from '@/lib/types';

import { Cell, CellViewOptions, Field, Visualisation } from '../types';
import { QueryMeta, useExplorationContext } from './exploration-context';
import { isRecordsCell } from './utils';
import { dereferencePipeline, flattenPipeline } from '../pipeline/utils';
import { generateVisualisation } from '../components/visualisation/utils';
import { useMetadataContext } from '../metadata-context';
import { removeViewOption, setViewOption, setViewOptions } from './utils/view-options';
import { ensureValidPipeline } from '../utils';

export interface ExplorationCellContextValue {
  cell: Cell;
  cellIndex: number;
  isSelectedCell: boolean;
  setCell: (cell: Cell) => void;
  setQueryMeta: (meta: QueryMeta) => void;
  setCellViewOption: (key: string, value?: string) => void;
  getCellViewOption: (key: string) => Json | undefined;
  isCollapsed: boolean;
  isCollapsible: boolean;
  toggleCollapsed: () => void;
  addVisualization: (visualisation: Visualisation) => void;
  addDefaultVisualization: () => void;
  drilldownByProperty: (record: Record<string, unknown>, field: Field, timezone: string) => void;
  setVisualisation: (idx: number, visualisation: Visualisation) => void;
  removeVisualisation: (idx: number) => void;
  isTableVisible: boolean;
  hideTable: () => void;
  showTable: () => void;
  isPipelinePreviewVisible: boolean;
  tableMode: 'table' | 'cards';
}

const defaultContextValue = Symbol();

const ExplorationCellContext = createContext<
  ExplorationCellContextValue | typeof defaultContextValue
>(defaultContextValue);

export const useExplorationCellContext = () => {
  const context = useContext(ExplorationCellContext);
  if (context === defaultContextValue) {
    throw new Error(
      'useExplorationCellContext must be used within a ExplorationCellContextProvider',
    );
  }
  return context;
};

const TableVisibilityKey = 'tableVisibility';
export const TableModeKey = 'viewMode';
export const PipelinePreviewKey = 'pipelinePreviewVisibility';

export const ExplorationCellContextProvider = ({
  cell,
  cellIndex,
  isCollapsible,
  children,
}: {
  cell: Cell;
  cellIndex: number;
  isCollapsible: boolean;
  children: React.ReactNode;
}) => {
  const {
    exploration,
    selectedCellIndex,
    setCellById,
    setQueryMeta,
    closeEditor,
    drillDownByProperty,
    getVariables,
  } = useExplorationContext();

  const { models, metrics: metrics } = useMetadataContext();

  const trackEvent = useTrackEvent();

  const isSelectedCell = selectedCellIndex === cellIndex;

  const wrappedSetCell = useCallback(
    (cell: Cell) => {
      setCellById(cell, cell.id);
    },
    [setCellById],
  );

  const wrappedSetQueryMeta = useCallback(
    (meta: QueryMeta | null) => setQueryMeta({ cellId: cell.id, meta }),
    [cell, setQueryMeta],
  );

  const setCellViewOption = (key: string, value?: string) => {
    wrappedSetCell(setViewOption(cell, key, value));
  };

  const getCellViewOption = (key: string) => get(cell.viewOptions, key, undefined);

  const resetCellHeightAndShowTable = () => {
    wrappedSetCell(
      setViewOptions(removeViewOption(cell, TableVisibilityKey), { height: undefined }),
    );
  };

  const isCollapsed = getCellViewOption('collapsed') === 'yes';
  const toggleCollapsed = () => {
    if (isSelectedCell) {
      closeEditor();
    }
    const updatedCell: CellViewOptions = { collapsed: isCollapsed ? 'no' : 'yes' };
    if (!isCollapsed) {
      updatedCell.height = undefined;
    }
    wrappedSetCell(setViewOptions(cell, updatedCell));
  };

  const isTableVisible = getCellViewOption(TableVisibilityKey) !== 'hidden';
  const hideTable = () => setCellViewOption(TableVisibilityKey, 'hidden');
  const showTable = () => resetCellHeightAndShowTable();

  const tableMode = getCellViewOption(TableModeKey) === 'cards' ? 'cards' : 'table';

  const isPipelinePreviewVisible = getCellViewOption(PipelinePreviewKey) !== 'hidden';

  const setVisualisations = (visualisations: Visualisation[]) => {
    if (!isRecordsCell(cell)) {
      return;
    }

    const updatedCell =
      !isTableVisible && visualisations.length === 0
        ? removeViewOption(cell, TableVisibilityKey)
        : cell;

    wrappedSetCell({
      ...updatedCell,
      visualisations,
    });
  };

  const removeVisualisation = (idx: number) => {
    if (!isRecordsCell(cell)) {
      return;
    }

    const visualisations = 'visualisations' in cell ? (cell.visualisations ?? []) : [];
    const { baseModelId, operations } = flattenPipeline(cell.pipeline, exploration);
    const analyticsProps = { baseModelId, operations, visualisations };
    const removingVisualisation = visualisations[idx];

    trackEvent('Visualisation Removed', {
      removingVisualisation,
      ...analyticsProps,
    });

    setVisualisations([...visualisations.slice(0, idx), ...visualisations.slice(idx + 1)]);
  };

  const setVisualisation = (idx: number, visualisation: Visualisation) => {
    if (!isRecordsCell(cell) || !('visualisations' in cell) || cell.visualisations === undefined) {
      return;
    }

    const visualisations = 'visualisations' in cell ? (cell.visualisations ?? []) : [];
    const { baseModelId, operations } = flattenPipeline(cell.pipeline, exploration);
    const analyticsProps = { baseModelId, operations, visualisations };

    trackEvent('Visualisation Changed', {
      visualisation,
      ...analyticsProps,
    });

    setVisualisations([
      ...visualisations.slice(0, idx),
      visualisation,
      ...visualisations.slice(idx + 1),
    ]);
  };

  const addVisualization = (visualisation: Visualisation) => {
    if (cell.kind === 'records') {
      setVisualisations([...(cell.visualisations ?? []), visualisation]);
    }
  };

  const addDefaultVisualization = () => {
    if (isRecordsCell(cell) && cell.pipeline !== undefined) {
      const dereferencedPipeline = dereferencePipeline(
        ensureValidPipeline(cell.pipeline, {
          models,
          metrics,
          variables: getVariables(),
          exploration,
        }),
        exploration,
      );
      addVisualization(
        generateVisualisation(dereferencedPipeline, { models, variables: getVariables(), metrics }),
      );
    }
  };

  return (
    <ExplorationCellContext.Provider
      value={{
        cell,
        cellIndex,
        isSelectedCell,
        setCell: wrappedSetCell,
        setQueryMeta: wrappedSetQueryMeta,
        setCellViewOption,
        getCellViewOption,
        isCollapsed: isCollapsed && isCollapsible,
        isCollapsible: isCollapsible,
        toggleCollapsed,
        addVisualization,
        addDefaultVisualization,
        drilldownByProperty: (record, field, timezone) =>
          drillDownByProperty(record, field, cell.id, timezone),
        setVisualisation,
        removeVisualisation,
        isTableVisible,
        hideTable,
        showTable,
        tableMode,
        isPipelinePreviewVisible,
      }}>
      {children}
    </ExplorationCellContext.Provider>
  );
};
